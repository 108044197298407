import { Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { Backdrop, Button, ButtonBase, Chip, CircularProgress, Grid, IconButton, List, ListItemButton, ListSubheader, Skeleton, ThemeProvider, ToggleButton, ToggleButtonGroup, createTheme, styled } from '@mui/material';
import * as XLSX from 'xlsx';
import ReactDOM from 'react-dom'; // Add this line to import ReactDOM
// import DataGrid from 'react-data-grid';
import { createRoot } from 'react-dom/client'; 
import { useRef } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import axios from 'axios';
import ExcelDisplay from './ExcelDisplay';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import VersionDisplay from './VersionDisplay';
import CreateWorkpaper from './CreateWorkpaper';

import TransparentButton from "../../../utils/TransparentButton";

import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SessionExpiredModal from '../../../utils/SessionExpiredModal';

import SearchIcon from '@mui/icons-material/Search';

import {formatDatetime} from '../../../utils/funtions'
import MessageModalModified from '../../../utils/MessageModalModified';
// import 'jspreadsheet';
// import JSSpreadsheet from 'jspreadsheet';
// import 'jspreadsheet-ce/dist/jspreadsheet.css';
// import JSSpreadsheet from 'jspreadsheet-ce';

const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: 'relative',
    borderRadius: '1vw', 
    transition: '0.1s',
    '&:hover, &.Mui-focusVisible': {
      zIndex: 1,
      backgroundColor: 'rgb(255, 255, 255, 0.05)',
      '& .MuiImageBackdrop-root': {
        opacity: 0.15,
      },
      '& .MuiImageMarked-root': {
        opacity: 0,
      },
      '& .MuiTypography-root': {
        border: '4px solid currentColor',
      },
    },
  }));



function formatABN(abn) {

    if(abn == 'NOT PROVIDED'){
        return abn
    }

    // Remove any non-digit characters in case the input is not clean
    let str = abn.replace(/\D/g, '');

    // Check if the input is exactly 11 digits, if not, handle as needed
    if (str.length !== 11) {
        console.error("Invalid ABN: ABN must be exactly 11 digits");
        return null;
    }

    // Insert spaces to format as ABN
    return str.replace(/(\d{2})(\d{3})(\d{3})(\d{3})/, '$1 $2 $3 $4');
}

const TextButton = styled(Button)(({ theme }) => ({
    color: 'white',
    textTransform: 'none',
    padding: '0.5vw 0.75vw'
  }));


export default (props) => {
    const navigate = useNavigate();
    const auth = useAuth();

    const [isVersionDisplayReloading, setIsVersionDisplayReloading] = useState(false)

    const [excelDisplayShow, setExcelDisplayShow] = useState(false)

    const [versionDisplayShow, setVersionDisplayShow] = useState(false)

    const [createWorkpaperShow, setCreateWorkpaperShow] = useState(false)

    const [reactivateSubscriptionShow, setReactivateSubscriptionShow] = useState(false)

    const [displayMode, setDisplayMode] = useState('folder')

    const [retrievedPeriods, setRetrievedPeriod] = useState([])

    const [selectedGroup, setSelectedGroup] = useState({clientId: -1, clientName: '', clientAbn: '', startDate: '', endDate: '', version: -1})

    // Table
    const [data, setData] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);

    const [isSubscriptionActive, setIsSubscriptionActive] = useState(false)


    //Excel
    const [workbook, setWorkbook] = useState()

    const [excelRequest, setExcelRequest] = useState()

    const [timer, setTimer] = useState()


    // Search functionality
    const [searchTerm, setSearchTerm] = useState("");

    const [filteredData, setFilteredData] = useState([]);

    const [selectedItems, setSelectedItems] = useState(new Array(filteredData.length).fill(false));
    const [selectAll, setSelectAll] = useState(false);

    const [selectedRows, setSelectedRows] = useState([]);

    const [itemsPerPage, setItemsPerPage] = useState(20)

    const handleCheckboxChange = (index, checked) => {
        if (checked) {
          setSelectedRows([...selectedRows, filteredData[index]]);
        } else {
          setSelectedRows(selectedRows.filter((_, i) => i !== index));
        }
      };

    const handleExport = () => {
    selectedRows.forEach((row) => {
        const requestId = row.request_id;
        const clientName = row.client_name;
        const period = `${new Date(row.start_date).toLocaleString('en-US', { day: 'numeric', month:'short', year: 'numeric' })} to ${new Date(row.end_date).toLocaleString('en-US', { day: 'numeric', month:'short', year: 'numeric' })}`;
        handleDownload(requestId, clientName, period);
    });
    };

    const handleSelectAll = () => {
      const newSelectAll = !selectAll;
      setSelectAll(newSelectAll);
      setSelectedItems(new Array(filteredData.length).fill(newSelectAll));
    };

    

    let indexOfLastItem = currentPage * itemsPerPage;
    let indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const [currentItems, setCurrentitems] = useState(filteredData.slice(indexOfFirstItem, indexOfLastItem))

    // Page change handlers
    const nextPage = () => {
        if (currentPage < Math.ceil(filteredData.length / itemsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    useEffect(() => {
        // Calculate the items for the current page
        let indexOfLastItem = currentPage * itemsPerPage;
        let indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setCurrentitems(filteredData.slice(indexOfFirstItem, indexOfLastItem))
        console.log(currentItems)
    }, [filteredData]);


    useEffect(() => {
        // Calculate the items for the current page
        let indexOfLastItem = currentPage * itemsPerPage;
        let indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setCurrentitems(filteredData.slice(indexOfFirstItem, indexOfLastItem))
    }, [currentPage]);



    // Popup
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [backdropOpen, setBackdropOpen] = React.useState(false);
    const handleBackdropClose = () => {
        setBackdropOpen(false);
    };
    const handleBackdropOpen = () => {
        setBackdropOpen(true);
    };

    const [reloadButtonDisabled, setReloadButtonDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [sessionExpired, setSessionExpired] = useState(false)

    async function reload(){
        // if(!reloadButtonDisabled){
        //     setCurrentPage(1)
            // setSelectedGroup({clientId: -1, clientName: '', clientAbn: '', startDate: '', endDate: '', version: -1})
            if(displayMode == 'folder'){
                if(selectedGroup.clientId == -1){
                
                    setReloadButtonDisabled(true); // Disable the reload button
                    try{
                        let response = await fetch(`${window.origin}/api/creator/request-groups`, {
                            headers: {
                                "Authorization": `Bearer ${auth.user?.access_token}`,
                                'Cache-Control': 'no-cache',
                                'If-None-Match': '0',
                            }
                        })

                        if(response.status === 403){
                            setSessionExpired(true)
                            return
                        }


                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        }

                        let data = await response.json()

                        

                        

                        console.log(data)
                        setData(data);
                        setFilteredData(data);
                    }catch(e){
                        console.log(e)
        
                    }
                }else{
                    retrievePeriods(selectedGroup.clientId)
                }
            
            }else if (displayMode == 'list'){
                // setIsLoading(true); // Start showing the loading spinner
                // setReloadButtonDisabled(true); // Disable the reload button
                // handleBackdropOpen()

                try{
                    let response = await fetch(`${window.origin}/api/creator/requests`, {
                        headers: {
                            "Authorization": `Bearer ${auth.user?.access_token}`,
                            'Cache-Control': 'no-cache',
                            'If-None-Match': '0',
                        }
                    })

                    if(response.status === 403){
                        setSessionExpired(true)
                        return
                    }

                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                      }

                    let data = await response.json()

                    console.log(data)
                    setData(data);
                    setFilteredData(data);

                }catch(error){
                    console.error('Error fetching data:', error);
                }
            }
        }
        // }
    

    //version3///////////////////////////////////////////////////////////////////
    const retrievePeriods = (clientId) => {
        const url = `${window.origin}/api/creator/request-groups/${clientId}/periods`;
    
        fetch(url, {
            headers: {
                "Authorization": `Bearer ${auth.user?.access_token}`,
                'Cache-Control': 'no-cache',
                'If-None-Match': '0',
            }
        })
            .then((response) => {
                if(response.status === 403){
                    setSessionExpired(true)
                    throw new Error('Session expired');
                }
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                return response.json();
            })
            .then((data) => {
                
                console.log(data)
                setRetrievedPeriod(data);
                handleBackdropClose();
                setIsLoading(false)
            })
            .catch((error) => {
                console.error('There has been a problem with your fetch operation:', error);
                handleBackdropClose();
            });
    };

    //version3///////////////////////////////////////////////////////////////////
    const handleView = (requestId, subVersion) => {
        handleBackdropOpen();
        const url = `${window.origin}/api/creator/workpaper/edit?requestid=${requestId}&subversion=${subVersion}`;
    
        fetch(url, {
            headers: {
                "Authorization": `Bearer ${auth.user?.access_token}`,
                'Cache-Control': 'no-cache',
                'If-None-Match': '0',
            }
        })
            .then((response) => {
                if(response.status === 403){
                    setSessionExpired(true)
                    throw new Error('Session expired');
                }
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                return response.json();
            })
            .then((json_resp) => {

                setWorkbook(json_resp.sharingLink)

                setExcelDisplayShow(true)
    
                handleBackdropClose();
            })
            .catch((error) => {
                console.error('There has been a problem with your fetch operation:', error);
                handleBackdropClose();
            });
    };


    const handleReplicate = (requestId, subVersion) => {
        handleBackdropOpen();
        const url = `${window.origin}/api/creator/workpaper/replicate?requestid=${requestId}&subversion=${subVersion}`;
    
        setIsVersionDisplayReloading(true)

        fetch(url, {
            headers: {
                "Authorization": `Bearer ${auth.user?.access_token}`,
                'Cache-Control': 'no-cache',
                'If-None-Match': '0',
            }
        })
        .then((response) => {
            if(response.status === 403){
                setSessionExpired(true)
                throw new Error('Session expired');
            }
            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }
            return response.json();
        })
        .then((json_resp) => {

            console.log(json_resp)
            handleBackdropClose();
            setIsVersionDisplayReloading(false)
        })
        .catch((error) => {
            console.error('There has been a problem with your fetch operation:', error);
            handleBackdropClose();
            setIsVersionDisplayReloading(false)
        });
    };





    const handleDownload = (requestId, client_name, period) => {
        handleBackdropOpen()
        // Replace with your server's URL and correct endpoint
        const url = `${window.origin}/api/creator/export?requestid=${requestId}`; // Add the correct query parameter for the ID or other identifier you need
    
        fetch(url, {
            headers: {
                "Authorization": `Bearer ${auth.user?.access_token}`,
                'Cache-Control': 'no-cache',
                'If-None-Match': '0',
            }
        })
        .then((response) => {
            if(response.status === 403){
                setSessionExpired(true)
                throw new Error('Session expired');
            }
            // Check if the response is ok (status code 200)
            if (!response.ok) {
              throw new Error('Network response was not ok ' + response.statusText);
            }
            return response.blob(); // Convert the response to a blob
          })
        .then((blob) => {
            // Create a new URL for the blob object
            const objectUrl = URL.createObjectURL(blob);

            // Create a temporary anchor to trigger download
            const link = document.createElement('a');
            link.href = objectUrl;
            link.download = `Workpaper_${client_name}_${period}.xlsx`; // Name the file here
            document.body.appendChild(link);
            link.click(); // Trigger the download

            // Clean up the URL object and remove the temporary anchor
            URL.revokeObjectURL(objectUrl);
            document.body.removeChild(link);

            handleBackdropClose()
        })
        .catch((error) => {
            console.error('There has been a problem with your fetch operation:', error);
        });
      };


    useEffect(() => {
        setFilteredData(data.filter(row => {
            // setCurrentPage(1)
                return row.client_abn.includes(searchTerm) || row.client_name.toLowerCase().includes(searchTerm.toLowerCase())
            }
        ))
        // Calculate the items for the current page
        let indexOfLastItem = currentPage * itemsPerPage;
        let indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setCurrentitems(data.slice(indexOfFirstItem, indexOfLastItem))
    }, [searchTerm, data]);


    useEffect(() => {
        // Calculate the items for the current page
        let indexOfLastItem = currentPage * itemsPerPage;
        let indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setCurrentitems(data.slice(indexOfFirstItem, indexOfLastItem))
    }, [currentPage]);

    const reloadWithLoading = async () => {
        setIsLoading(true); // Start showing the loading spinner
        handleBackdropOpen()
        await reload()
        setIsLoading(false); // Start showing the loading spinner
        handleBackdropClose()
    }

    useEffect( () => {
        if(displayMode == 'folder'){
            setItemsPerPage(12)
        }else{
            setItemsPerPage(8)
        }
        reloadWithLoading()

        // clearInterval(timer)

        let timer = setInterval(() => {
            reload()
        }, 5000)

        return () => {
            clearInterval(timer);
            console.log("Interval cleared.");
          };

    }, [displayMode])

    useEffect(() => {
        fetch(`${window.origin}/api/creator/active-subscription`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${auth.user?.access_token}`
            }
        }).then(response => {
            // if(response.status === 403){
            //     setSessionExpired(true)
            //     throw new Error('Session expired');
            // }       
            return response.json()

        }).then(plan => {
            setIsSubscriptionActive(plan[0].subscription_status == 'active')
        })
    }, [])

    

    return (
        <div className={props.imgClass} style={{ textAlign: 'center', position: 'relative', display: 'flex', flexDirection: 'row' }}>
            <SessionExpiredModal show={sessionExpired}/>
            <MessageModalModified show={reactivateSubscriptionShow} onClose={() => {setReactivateSubscriptionShow(false)}} onClick={() => {navigate('/mydigitalworkers/gayatri/settings', {state: {tab: "SubscriptionSettings"}})}} message={'Subscription Canceled'} description={'Your subscription has been canceled. Please re-activate your subscription to continue.'} buttonText={'Manage Subscription'}/>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: 'calc(100vh - 6vw)', padding: 0, fontSize: '0.9vw'}}>
                <div style={{ margin: '1.3vw 1vw', display: 'flex', flexDirection: 'row', alignItems: 'center', lineHeight:'0'}}>
                    <div style={{ paddingLeft: '1vw' }}>

                        { (selectedGroup.clientId == -1 || displayMode != 'folder') && <div style={{textAlign: 'left', marginBottom: '0.5vw'}}>
                                <div style={{backgroundColor: 'rgb(255, 255, 255, 0.08)', borderRadius: '50px', padding: '0.2vw'}}>
                                    <ThemeProvider theme={darkTheme}>

                                        <IconButton aria-label="delete" style={{backgroundColor: 'rgb(255, 255, 255, 0.15)', padding: '0.5vw', fontSize: '0.5vw', color: 'rgb(255, 255, 255, 0.7)', cursor: 'none'}}>
                                            <SearchIcon style={{fontSize: '1vw'}}/>
                                        </IconButton>
                                    </ThemeProvider>
                                    <Form.Control 
                                        id="client-search-bar"
                                        style={{
                                            display: 'inline',  backgroundColor: 'rgba(255,255,255,0)', padding: 0, paddingLeft: '0.8vw',
                                            border: '1px solid rgb(255, 255, 255, 0)', borderRadius: '6px', fontSize: '0.9vw', maxWidth: '80%', marginLeft:'0' , fontWeight: '200'
                                        }} 
                                        className='shadow-none'
                                        type="text" 
                                        placeholder="Search" 
                                        value={searchTerm}
                                        onChange={e => setSearchTerm(e.target.value)}
                                    />
                                </div>
                        </div>}
                        {selectedGroup.clientId != -1  && displayMode == 'folder' &&
                            <div style={{textAlign: 'left', marginBottom: '0.5vw'}}>
                                <div style={{backgroundColor: 'rgb(255, 255, 255, 0.08)', borderRadius: '50px', padding: '0.2vw'}}>
                                    <ThemeProvider theme={darkTheme}>

                                        <IconButton aria-label="delete" style={{backgroundColor: 'rgb(255, 255, 255, 0.15)', padding: '0.5vw', fontSize: '0.5vw', color: 'rgb(255, 255, 255, 0.7)'}} onClick={() => {
                                            setSelectedGroup((prevState) => {
                                                return {...prevState, clientId: -1}
                                            })
                                            setSearchTerm('')
                                        }}>
                                            <ArrowBackIcon style={{fontSize: '1vw'}}/>
                                        </IconButton>
                                    </ThemeProvider>
                                    <span style={{fontSize: '0.85vw', marginLeft: '1vw', fontWeight: '500', marginRight: '1.5vw', paddingTop: '0.5vw'}}>{selectedGroup.clientName}</span>
                                </div>
                            </div>
                        }
                    </div>
                    <div style={{ flex: 1, textAlign: 'right', paddingLeft: '0', marginRight: '0.5vw'}}>
                        <span style={{flex: 1, fontSize: '1.8vh', textAlign: 'center', flexDirection: 'row', paddingRight: '0', marginRight: '0'}}>
                            <div style={{display: 'inline-block', backgroundColor: 'rgb(255, 255, 255, 0.1)', borderRadius: '2vw', padding: '0.2vw'}}>
                                <OverlayTrigger
                                    key={'top-submit'}
                                    placement={'top'}
                                    overlay={
                                        <Tooltip id={`tooltip-submit`}>
                                            Folder View
                                        </Tooltip>
                                    }
                                    >
                                <span style={{}}>
                                    
                                         <TransparentButton onClick={() => {setDisplayMode('folder')}} style={{padding: '0.3vw 0.75vw'}} selected={displayMode == 'folder'}><FolderOpenIcon style={{fontSize: '1vw'}}/></TransparentButton>
                                

                                    
                                </span>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    key={'top-submit'}
                                    placement={'top'}
                                    overlay={
                                        <Tooltip id={`tooltip-submit`}>
                                            History View
                                        </Tooltip>
                                    }
                                    >
                                    <span style={{}}><TransparentButton onClick={() => {setDisplayMode('list')}} style={{padding: '0.3vw 0.75vw'}} selected={displayMode == 'list'}><FormatAlignJustifyIcon  style={{fontSize: '1vw'}}/></TransparentButton></span>
                                </OverlayTrigger>
                            </div>
                        </span>
                            {/* <OverlayTrigger
                                key={'top-submit'}
                                placement={'top'}
                                overlay={
                                    <Tooltip id={`tooltip-submit`}>
                                        Refresh
                                    </Tooltip>
                                }
                                >
                                    <TextButton id="refresh-workpaper-list" variant='text' style={{fontSize: '0.9vw', cursor: 'pointer', padding: '0.75vw', minWidth: 0, borderRadius: '2vw', backgroundColor: 'rgb(255, 255, 255, 0.1)'}} onClick={reload}>
                                        <i class="fa-solid fa-rotate-right" style={{fontSize: '0.9vw', width: '0.95vw', height: '0.95vw'}}></i>
                                    </TextButton>
                                
                            </OverlayTrigger> */}
                        <OverlayTrigger
                            key={'top-submit'}
                            placement={'top'}
                            overlay={
                                <Tooltip id={`tooltip-submit`}>
                                    Create Request
                                </Tooltip>
                            }
                            >
                                <TextButton id="create-new-workpaper" variant='text' style={{ fontSize: '0.9vw',cursor: 'pointer', marginLeft: '0.5vw', padding: '0.75vw', minWidth: 0, borderRadius: '2vw', backgroundColor: 'rgb(255, 255, 255, 0.1)', marginRight: '0.5vw', marginLeft: '1vw'}} onClick={() => {
                                    if(!isSubscriptionActive){
                                        setReactivateSubscriptionShow(true)
                                        return
                                    }
                                    setCreateWorkpaperShow(true)
                                }}>
                                    <i className="fa-solid fa-plus" style={{fontSize: '0.9vw', width: '0.95vw', height: '0.95vw'}}></i>
                                </TextButton>
                            
                        </OverlayTrigger>
                        
                        {/* <TextButton
                            variant='text'
                            style={{ fontSize: '0.9vw', cursor: 'pointer', marginLeft: '0.5vw' }}
                            onClick={handleExport}
                            >
                            <i className="fa-solid fa-arrow-down" style={{ paddingRight: '8px', fontSize: '0.9vw' }}></i>
                            Export
                        </TextButton> */}
                    </div>
                </div>

                {/* export all */}
                <Modal 
                    show={show} 
                    onHide={handleClose}
                    style={{
                        background: 'rgba(255, 255, 255, 0.1)', 
                        backdropFilter: 'blur(10px)',
                        borderRadius: '10px',
                        border: '1px solid rgba(255, 255, 255, 0.2)',
                        boxShadow: '4px 4px 15px rgba(0, 0, 0, 0.1)',
                        padding: '20px',
                    }}
                >
                    <Modal.Header 
                        closeButton={false} 
                        style={{
                            border: 'none',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}
                    >
                        <Modal.Title>Export All</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body>
                    <Modal.Footer 
                        style={{
                            border: 'none',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            borderTop:'1px solid rgba(255, 255, 255, 0.2)'
                        }}
                    >
                        <p style={{ color: '#ee7170', fontWeight: '600', cursor: 'pointer' }} onClick={handleClose}>
                            Export All
                        </p>
                    </Modal.Footer>
                </Modal>
                { displayMode == 'folder' && <>
                <div style={{ lineHeight: '2vw', marginLeft: '2vw', marginRight: '2vw', borderRadius: '1vw', textAlign: 'left' }}>
                    
                { currentItems.length != 0 && selectedGroup.clientId == -1 && <Grid id="request-folder-view" container spacing={2}>
                    { !isLoading && currentItems.map((row, index) => {
                        let period = `${new Date(row.start_date).toLocaleString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })} to ${new Date(row.end_date).toLocaleString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })}`
                        return (
                            <Grid style={{width: '100%'}} id={`request-folder-view-item-${row.client_abn}`} item xs={4} key={index} spacing={1}>
                                <ImageButton focusRipple style={{width: '100%', textAlign: 'left'}}>
                                <div style={{width: '100%', backgroundColor: 'rgb(255, 255, 255, 0.06)', borderRadius: '1vw', cursor:'pointer', border: '0.05vw solid rgb(255, 255, 255, 0)'}} 
                                    onClick={() => {
                                        // setVersionDisplayShow(true)
                                        handleBackdropOpen()
                                        setIsLoading(true)
                                        // setIsVersionDisplayReloading(true)
                                        setSelectedGroup({
                                            clientId: row.client_id,
                                            clientName: row.client_name,
                                            clientAbn: row.client_abn
                                        })
                                        retrievePeriods(row.client_id)
                                        // setIsVersionDisplayReloading(false)
                                    }}>
                                    <div style={{display: 'flex',flexDirection: 'row', alignItems: 'center'}}>
                                        <IconButton style={{margin: '1vw', backgroundColor: 'rgb(255, 255, 255, 0.06)'}} disabled>
                                            <FolderOpenIcon style={{margin: '0.5vw', fontSize: '2vw', color: 'rgb(255, 255, 255, 0.5)'}}/>
                                        </IconButton>
                                        <div style={{flex: 1, padding: '0.5vw', paddingLeft: '0', display: 'flex', flexDirection: 'column', overflow: 'hidden', marginRight: '1vw'}}>
                                            <p style={{fontSize: '0.9vw', margin: 0, lineHeight: '1.5vw', overflowX: 'hidden', whiteSpace: 'nowrap', fontWeight: '600', color: 'rgb(255, 255, 255, 0.9)', marginBottom: '0.3vw', width: '100%'}}>{row.client_name}</p>
                                            <p style={{fontSize: '0.75vw', lineHeight: '1.5vw', margin: 0, fontWeight: '400', color: 'rgb(255, 255, 255, 0.7)'}}>{formatABN(row.client_abn)}</p>
                                        </div>
                                    </div>
                                    {/* <div style={{padding: '1.3vw', paddingTop: '0'}}>
                                        <p style={{fontSize: '0.8vw', lineHeight: '1.5vw', margin: 0, fontWeight: '500', color: 'rgb(255, 255, 255, 0.9)'}}><span style={{color: 'rgb(255, 255, 255, 0.6)'}}>Period:</span> {period}</p>
                                        <p style={{fontSize: '0.8vw', lineHeight: '1.5vw', margin: 0, fontWeight: '500', color: 'rgb(255, 255, 255, 0.85)'}}><span style={{color: 'rgb(255, 255, 255, 0.6)'}}>Latest Version:</span> {row.latest_version}</p>
                                        
                                    </div> */}
                                </div>
                                </ImageButton>
                            </Grid>
                        )
                    })}
                </Grid>}

                { currentItems.length == 0 && <>
                    <div style={{textAlign: 'center', backgroundColor: 'rgb(255, 255, 255, 0.03)', borderRadius: '1vw', padding: '3vw'}}>
                        <p style={{fontSize: '1vw', margin: '0', marginBottom: '0.5vw', color: 'rgb(255, 255, 255, 0.8)'}}>No Workpaper Found</p>
                        <p style={{fontSize: '0.8vw', margin: '0', color: 'rgb(255, 255, 255, 0.7)'}}>Click '+' button to create a new workpaper</p>
                    </div>
                </> }

                { selectedGroup.clientId != -1 && <Grid container spacing={2}>
                    { !isLoading && retrievedPeriods.map((row, index) => {
                        console.log(currentItems)
                        let period = `${new Date(row.start_date).toLocaleString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })} to ${new Date(row.end_date).toLocaleString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })}`
                        return (
                            <Grid item xs={3} key={index} spacing={1}>
                                <ImageButton focusRipple style={{width: '100%', textAlign: 'left'}}>
                                <div style={{width: '100%', backgroundColor: 'rgb(255, 255, 255, 0.06)', borderRadius: '1vw', cursor:'pointer', border: '0.05vw solid rgb(255, 255, 255, 0)'}} 
                                    onClick={() => {
                                        setVersionDisplayShow(true)
                                        setIsVersionDisplayReloading(true)
                                        setSelectedGroup({
                                            clientId: row.client_id,
                                            clientName: row.client_name,
                                            clientAbn: row.client_abn, 
                                            startDate: row.start_date, 
                                            endDate: row.end_date,
                                            version: -1
                                        })
                                        setIsVersionDisplayReloading(false)
                                    }}>
                                    <div style={{display: 'flex',flexDirection: 'row', alignItems: 'center'}}>
                                        <IconButton style={{margin: '1vw', backgroundColor: 'rgb(255, 255, 255, 0.06)'}} disabled>
                                            <FolderOpenIcon style={{margin: '0.5vw', fontSize: '2vw', color: 'rgb(255, 255, 255, 0.5)'}}/>
                                        </IconButton>
                                        <div style={{flex: 1, padding: '0.5vw', paddingLeft: '0'}}>
                                            <p style={{fontSize: '0.9vw', margin: 0, lineHeight: '1.5vw', fontWeight: '600', color: 'rgb(255, 255, 255, 0.9)'}}>{period}</p>
                                            <p style={{fontSize: '0.75vw', lineHeight: '1.5vw', margin: 0, fontWeight: '400', color: 'rgb(255, 255, 255, 0.7)'}}><span style={{color: 'rgb(255, 255, 255, 0.6)'}}>Latest Version:</span> {row.latest_version}</p>
                                        </div>
                                    </div>
                                    {/* <div style={{padding: '1.3vw', paddingTop: '0'}}>
                                        <p style={{fontSize: '0.8vw', lineHeight: '1.5vw', margin: 0, fontWeight: '500', color: 'rgb(255, 255, 255, 0.9)'}}><span style={{color: 'rgb(255, 255, 255, 0.6)'}}>Period:</span> {period}</p>
                                        <p style={{fontSize: '0.8vw', lineHeight: '1.5vw', margin: 0, fontWeight: '500', color: 'rgb(255, 255, 255, 0.85)'}}><span style={{color: 'rgb(255, 255, 255, 0.6)'}}>Latest Version:</span> {row.latest_version}</p>
                                        
                                    </div> */}
                                </div>
                                </ImageButton>
                            </Grid>
                        )
                    })}
                    
                </Grid>}
                   
                </div>

                { selectedGroup.clientId == -1 && <div style={{ marginTop: '1vw', marginLeft: '2vw', marginRight: '2vw', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', textAlign: 'left' }}>
                    <div style={{ flex: 1, marginLeft: '0.5vw'  }}>
                        <p style={{ color: 'rgb(255, 255, 255, 0.5)', fontSize: '0.9vw', lineHeight:'normal' }}>
                            Showing {indexOfFirstItem+1} to {filteredData.length>indexOfLastItem?indexOfLastItem:filteredData.length} of {filteredData.length} entries
                        </p>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <button
                            onClick={prevPage}
                            disabled={currentPage === 1}
                            style={{
                                width: '2.5vw',
                                height: '2.5vw',
                                borderRadius: '0.5vw',
                                marginRight: '6px',
                                color: currentPage === 1 ? 'gray' : 'white',
                                backgroundColor: 'rgb(255, 255, 255, 0.02)',
                                border: '0.5px solid rgb(255, 255, 255, 0.3)',
                                // color: 'white',
                                cursor: currentPage === 1 ? 'not-allowed' : 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '0.9vw',
                            }}
                        >
                            <span dangerouslySetInnerHTML={{ __html: '&larr;' }} />
                        </button>

                        <button
                            onClick={nextPage}
                            disabled={currentPage === Math.ceil(data.length / itemsPerPage)}
                            style={{
                                width: '2.5vw',
                                height: '2.5vw',
                                borderRadius: '0.5vw',
                                marginLeft: '6px',
                                color: 'white',
                                backgroundColor: 'rgb(255, 255, 255, 0.02)',
                                border: '0.5px solid rgb(255, 255, 255, 0.3)',
                                cursor: currentPage === Math.ceil(data.length / itemsPerPage) ? 'not-allowed' : 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '0.9vw',
                            }}
                        >
                            <span dangerouslySetInnerHTML={{ __html: '&rarr;' }} />
                        </button>
                    </div>
                </div>}
                </>}
                { displayMode == 'list' && <><div style={{ lineHeight: '2vw', marginLeft: '2vw', marginRight: '2vw', border: '1px solid rgb(255, 255, 255, 0.15)', borderRadius: '1.5vw', textAlign: 'left' }}>
                    
                       
                        <ThemeProvider theme={darkTheme}>

                            {/* table */}
                            <div style={{lineHeight: '2vw', borderRadius: '1.5vw', textAlign: 'left'}}>
                                <List id="request-table" className="table table-hover table-dark" style={{ color: 'rgb(255, 255, 255, 0.9)', borderRadius: '1.5vw', fontSize: '0.75vw', backgroundColor: 'rgb(255, 255, 255, 0.0)', margin: 0, padding: 0}}>
                                    <ListSubheader id="request-table-header" style={{display: 'flex', fontWeight: '600', fontSize: '0.7vw', lineHeight: '3vw', backgroundColor: 'rgb(255, 255, 255, 0.06)', color: 'rgb(255, 255, 255, 0.8)', borderTopLeftRadius: '1.5vw', borderTopRightRadius: '1.5vw', padding: '0vw 2vw'}}>
                                        {/* <div style={{display: 'flex', fontWeight: '600'}}> */}
                                            <div scope="col" name="abn" style ={{padding:'0.1vw 0vw', flex: 3}}>CLIENT ABN</div>
                                            <div scope="col" name="client-name" style ={{padding:'0.1vw 0vw', flex: 6}}>CLIENT NAME</div>
                                            <div scope="col"  name="period" style ={{padding:'0.1vw 0vw', flex: 4}}>PERIOD</div>
                                            <div scope="col"  name="version" style ={{padding:'0.1vw 0vw', flex: 3}}>VERSION</div>
                                            <div scope="col"  name="created-on" style ={{padding:'0.1vw 0vw', flex: 3}}>CREATED ON</div>
                                            <div scope="col"  name="status" style ={{padding:'0.1vw 0vw', flex: 1.5}}>STATUS</div>
        
                                        {/* </div> */}
                                    </ListSubheader>
                                    <div style={{fontSize: '0.75vw'}}>
                                        {filteredData.length > 0 && !isLoading && currentItems.map((row, index) => (
                                            <ListItemButton id={`request-table-item-${row.request_id}`} key={index} style={{"cursor": "pointer", lineHeight: '2vw', display: 'table-row', display: 'flex', borderTop: '0.05vw solid rgb(255, 255, 255, 0.05)', fontSize: '0.8vw', color: 'rgb(255, 255, 255, 0.75)', padding: '0.5vw 2vw'}} onClick={() => {

                                                    setVersionDisplayShow(true)
                                                    setIsVersionDisplayReloading(true)
                                                    setSelectedGroup({
                                                        clientId: row.client_id,
                                                        clientName: row.client_name,
                                                        clientAbn: row.client_abn, 
                                                        startDate: row.start_date, 
                                                        endDate: row.end_date,
                                                        version: row.version
                                                    })
                                                    setIsVersionDisplayReloading(false)
                                                }}>          
                                                {/* <ListItemButton style={{flex: 1}}> */}

                                                <div scope="col" name="abn" style ={{padding:'0.1vw 0vw', flex: 3, fontFamily: "'Consolas', Courier, monospace", letterSpacing: formatABN(row.client_abn) == 'NOT PROVIDED'? '0.15vw': '0.05vw', flex: 3}}>{formatABN(row.client_abn)}</div>
                                                <div scope="col" name="client-name" style ={{padding:'0.1vw 0vw', flex: 6}}>{row.client_name}</div>
                                                <div scope="col"  name="period" style ={{padding:'0.1vw 0vw', flex: 4}}>{new Date(row.start_date).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric' })} - {new Date(row.end_date).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric' })}</div>
                                                <div scope="col"  name="version" style ={{padding:'0.1vw 0vw', flex: 3}}>Version {row.version}</div>
                                                <div scope="col"  name="created-on" style ={{padding:'0.1vw 0vw', flex: 3}}>{formatDatetime(row.created_on)}</div>
                                                <div scope="col"  name="status" style ={{padding:'0.1vw 0vw', flex: 1.5}}>{row.status == 'done'? 'SUCCESS': (row.status == 'ctlreq'? "SUBMITTED": (row.status == 'processing'? "PROCESSING": "FAILED"))}</div>

                                                    {/* <div scope="col" name="abn" style ={{padding:'0.1vw 0vw', fontFamily: "'Consolas', Courier, monospace", letterSpacing: formatABN(row.client_abn) == 'NOT PROVIDED'? '0.15vw': '0.05vw', flex: 3}}>{formatABN(row.client_abn)}</div>
                                                    <div scope="col" name="client-name" style ={{padding:'0.1vw 0vw', flex: 6}}>{row.client_name}</div>
                                                    <div scope="col"  name="period" style ={{padding:'0.1vw 0vw', flex: 4}}>{new Date(row.start_date).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric' })} - {new Date(row.end_date).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric' })}</div>
                                                    <div scope="col"  name="version" style ={{padding:'0.1vw 0vw', flex: 3}}>Version {row.version}</div>
                                                    <div scope="col"  name="created-on" style ={{padding:'0.1vw 0vw', flex: 3}}>{formatDatetime(row.created_on)}</div>
                             
                                                    <div scope="col"  name="status" style ={{padding:'0.1vw 1.5vw', flex: 1.5}}>{row.status == 'done'? 'SUCCESS': (row.status == 'ctlreq'? "SUBMITTED": (row.status == 'processing'? "PROCESSING": "FAILED"))}</div> */}
                                                    
                                                {/* </ListItemButton> */}
                                            </ListItemButton>
                                        ))}
                                        { filteredData.length == 0 && !isLoading &&
                                            <div style={{display: 'flex'}}>
                                                <div style={{flex: 1}}><p style={{textAlign: 'center', marginTop: '30px', marginBottom: '30px'}}>No workpaper found</p></div>
                                            </div>
                                        }
                                        { filteredData.length == 0 && isLoading &&
                                            <div style={{display: 'flex'}}>
                                                <div style={{flex: 1}}><p style={{textAlign: 'center', marginTop: '30px', marginBottom: '30px'}}><CircularProgress color="inherit" /></p></div>
                                            </div>
                                        }
                                        
                                    </div>
                                </List>
                            </div>
                        </ThemeProvider>
                    
                </div>
                
                
                <div style={{ marginTop: '1vw', marginLeft: '2vw', marginRight: '2vw', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', textAlign: 'left' }}>
                        <div style={{ flex: 1, marginLeft: '0.5vw' }}>
                            <p style={{ color: 'rgb(255, 255, 255, 0.5)', fontSize: '0.9vw', lineHeight:'normal' }}>
                                Showing {indexOfFirstItem+1} to {filteredData.length>indexOfLastItem?indexOfLastItem:filteredData.length} of {filteredData.length} entries
                            </p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <button
                                onClick={prevPage}
                                disabled={currentPage === 1}
                                style={{
                                    width: '2.5vw',
                                    height: '2.5vw',
                                    borderRadius: '0.5vw',
                                    marginRight: '6px',
                                    color: currentPage === 1 ? 'gray' : 'white',
                                    backgroundColor: 'rgb(255, 255, 255, 0.02)',
                                    border: '0.5px solid rgb(255, 255, 255, 0.3)',
                                    // color: 'white',
                                    cursor: currentPage === 1 ? 'not-allowed' : 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '0.9vw',
                                }}
                            >
                                <span dangerouslySetInnerHTML={{ __html: '&larr;' }} />
                            </button>

                            <button
                                onClick={nextPage}
                                disabled={currentPage === Math.ceil(data.length / itemsPerPage)}
                                style={{
                                    width: '2.5vw',
                                    height: '2.5vw',
                                    borderRadius: '0.5vw',
                                    marginLeft: '6px',
                                    color: 'white',
                                    backgroundColor: 'rgb(255, 255, 255, 0.02)',
                                    border: '0.5px solid rgb(255, 255, 255, 0.3)',
                                    cursor: currentPage === Math.ceil(data.length / itemsPerPage) ? 'not-allowed' : 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '0.9vw',
                                }}
                            >
                                <span dangerouslySetInnerHTML={{ __html: '&rarr;' }} />
                            </button>
                        </div>
                    </div>
                    </>}

            </div>
            <ExcelDisplay show={excelDisplayShow} workbook={workbook} onHide={()  => {setExcelDisplayShow(false)}} />
            <CreateWorkpaper show={createWorkpaperShow} onHide={()  => {setCreateWorkpaperShow(false)}} onRefresh={reload}/>
            <VersionDisplay show={versionDisplayShow} onHide={()  => {setVersionDisplayShow(false)}} selectedGroup={selectedGroup} downloadHandler={handleDownload} editHandler={handleView} replicateHandler={handleReplicate} isUpdating={isVersionDisplayReloading} onBackdropOpen={handleBackdropOpen} onBackdropClose={handleBackdropClose} onShow={setVersionDisplayShow}/>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropOpen}
                onClick={handleBackdropClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}
