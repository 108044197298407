import React from "react";
import { 
    useEffect, 
    useState,
} from "react";
import { 
    Outlet,
    useNavigate, 
    // useSearchParams,
} from "react-router-dom";

import { useAuth } from "react-oidc-context";
import { CircularProgress, Fade, IconButton, ThemeProvider } from "@mui/material";
import GayatryImg from "../../resources/GayatriPic.png";
import SubscriptionRequired from "./Subscription/SubscriptionRequired";
import GayatriSetup from "./SetUp/GayatriSetup";



import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { darkTheme } from "../../utils/funtions";


export default function About(props) {



    const auth = useAuth()

    const navigate = useNavigate()





    


    return (
        <div style={{width: '100%', height: '80vh', display: 'flex', flexDirection: 'row', paddingTop: '4vw'}}>
          <div style={{flex: 2, textAlign: 'right', paddingRight: '2vw', paddingLeft: '5vw'}}>
            <img src={GayatryImg} style={{width: '40vw', borderRadius:'2vw', marginTop: '4vw'}}/>
          </div>
          <div style={{flex: 2, paddingLeft: '2vw', paddingTop: '6vw', paddingRight: '5vw'}}>
            <p style={{fontSize: '1.5vw', marginBottom: '2vw'}}>Workpaper Generation Service</p>
            <ul style={{paddingLeft: '1vw', paddingRight: '2vw'}}>
                <li style={{fontSize: '0.9vw', lineHeight: '1.6vw', fontWeight: '300', color: 'rgb(255, 255, 255, 0.7)', marginBottom: '1vw'}}>Generate fully customizable workpapers by fetching data directly from ATO, Xero, and MYOB for precise tax reporting.</li>
                <li style={{fontSize: '0.9vw', lineHeight: '1.6vw', fontWeight: '300', color: 'rgb(255, 255, 255, 0.7)', marginBottom: '1vw'}}>Produce accurate, tailored reports with ease, reducing manual data entry and minimizing potential errors.</li>
                <li style={{fontSize: '0.9vw', lineHeight: '1.6vw', fontWeight: '300', color: 'rgb(255, 255, 255, 0.7)', marginBottom: '1vw'}}>Free up valuable time for accountants to focus on client insights, analysis, and strategic success.</li>
                <li style={{fontSize: '0.9vw', lineHeight: '1.6vw', fontWeight: '300', color: 'rgb(255, 255, 255, 0.7)', marginBottom: '1vw'}}>Simplify the reporting process for accountants, saving time and effort while increasing efficiency.</li>
            </ul>
          </div>
        </div>
    );
}