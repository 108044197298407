import { Button, CircularProgress, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import RedeemCodeModal from '../../../utils/RedeemCodeModal';

const TextButton = styled(Button)(({ theme }) => ({
    color: 'white',
    textTransform: 'none',
    padding: '0.5vw 0.75vw'
  }));

const plans = [
    {
        plan_id: "gayatri_plan1",
        plan_name: "Free Trial",
        description: "Start exploring the platform by creating workpapers for up to 5 clients.",
        price: 0
    },
    {
        plan_id: "gayatri_plan2",
        plan_name: "Small",
        description: "Expand your capabilities and manage workpapers for up to 200 clients.",
        price: 1000
    },
    {
        plan_id: "gayatri_plan3",
        plan_name: "Medium",
        description: "Scale your operations confidently with workpapers for up to 500 clients.",
        price: 2000
    },
    {
        plan_id: "gayatri_plan4",
        plan_name: "Large",
        description: "Streamline complex workflows with workpapers for up to 1000 clients.",
        price: 3000
    },
    {
        plan_id: "gayatri_plan5",
        plan_name: "Corporate",
        description: "Get unlimited workpapers to support your enterprise's extensive needs.",
        price: 3500
    }
]

function naviagteMicrosoftPortal(){
    window.open('https://appsource.microsoft.com/en-in/product/web-apps/spectargroupptyltd1590154088381.specia_digital_accountant_001','_blank')
}

function convertDate(isoString){
    const date = new Date(isoString);

    // Add one month to the date
    date.setMonth(date.getMonth() + 1);

    // Define an array of month names
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Format the date as "21 Apr 2024"
    const formattedDate = `${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()}`;

    return formattedDate

}

function SubscriptionSettings() {

    const [availablePlans, setAvailablePlans] = useState(plans)

    const [selectedPlan, setSelectedPlan] = useState()

    const [ nextEstimatedPayment, setNextEstimatedPayment] = useState()

    const [isLoading, setIsLoading] = useState(true)

    const [redeemCodeShow, setRedeemCodeShow] = useState(false)

    const [subscriptionCode, setSubscriptionCode] = useState('')

    const [redeemModalStatus, setRedeemModalStatus] = useState('input')

    const [isSubscriptionActive, setIsSubscriptionActive] = useState(false)

    const auth = useAuth()


    const codeSubmitHandler = async () => {
        let requestBody = {
            subscriptionType: 'code',
            subscriptionValue: subscriptionCode
        }

        setRedeemModalStatus('submitting')


        try{
            const response = await fetch(`${window.origin}/api/subscription`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${auth.user?.access_token}`,
                },
                body: JSON.stringify(requestBody)
            })
            if(response.status === 403){
                // setSessionExpired(true)
                throw new Error('Session expired');
            }
            
            const json_req = await response.json();

            let status = json_req.status

            if(status == 'success'){
                setRedeemModalStatus('success')
            }else if (status == 'warning'){
                setRedeemModalStatus('warning')
            }
        }catch(error){
            console.log(error)
        }
    }


    useEffect(() => {
        fetch(`${window.origin}/api/creator/subscriptions`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${auth.user?.access_token}`
            }
        }).then(response => {
            // if(response.status === 403){
            //     setSessionExpired(true)
            //     throw new Error('Session expired');
            // }       
            return response.json()

        }).then(plans => {
            setAvailablePlans(plans)
        })

        fetch(`${window.origin}/api/creator/active-subscription`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${auth.user?.access_token}`
            }
        }).then(response => {
            // if(response.status === 403){
            //     setSessionExpired(true)
            //     throw new Error('Session expired');
            // }       
            return response.json()

        }).then(plan => {
            setSelectedPlan(plan[0].plan_id)
            setIsSubscriptionActive(plan[0].subscription_status == 'active')
            setNextEstimatedPayment(convertDate(plan[0].subscription_start_at))

            setIsLoading(false)
        })
    }, [])



    return (
        <>
        <RedeemCodeModal show={redeemCodeShow} onClose={() => {setRedeemCodeShow(false)}} code={subscriptionCode} status={redeemModalStatus} onSubmit={codeSubmitHandler} codeChangeHandler={(e) => setSubscriptionCode(e.target.value)}/>
        { !isLoading && <div>
            <div style={{display: 'flex', flexDirection: 'row', marginTop: '2vw', marginBottom: '2vw', justifyContent: 'center',}}>
                {availablePlans.map((plan, index) => 
                    <div key={index} style={{backgroundColor: selectedPlan == plan.plan_id?'rgb(255, 255, 255, 0.9)':' rgb(255, 255, 255, 0.06)', padding: '1.5vw', flex: 1, textAlign: 'left', borderRadius: '0.6vw', margin: '0', marginRight: '0.75vw', cursor: 'pointer'}} 
                        >
                        <p style={{fontSize: '1.3vw', fontWeight: '500', marginBottom: '1.1vw', marginTop: '0.5vw', color:  selectedPlan == plan.plan_id?'rgb(0, 0, 0, 1)':' rgb(255, 255, 255, 0.9)'}}>{plan.plan_name}</p>
                        <p style={{textAlign: 'left', color: 'rgb(255, 255, 255, 0.7)', fontSize: '0.7vw', color:  selectedPlan == plan.plan_id?'rgb(0, 0, 0, 0.8)':' rgb(255, 255, 255, 0.8)', height: '6vw', margin: 0}}>{plan.description}</p>
                        <p style={{textAlign: 'left', color: selectedPlan == plan.plan_id?'rgb(0, 0, 0, 0.9)':' rgb(255, 255, 255, 0.9)', margin: 0, marginBottom: '0.5vw'}}><span style={{fontWeight: '500', fontSize: '1.2vw'}}>${plan.price.toLocaleString('en-US')}</span><span style={{fontWeight: '500', fontSize: '0.8vw'}}>/month</span></p>
                    </div>
                )}
                
                

            </div>
            <div>
                { isSubscriptionActive && <p style={{fontSize: '0.85vw', textAlign: 'center'}}><span style={{color: 'rgb(255, 255, 255, 0.7)', fontWeight: '400'}}>Next estimated payment is</span> <span style={{color: 'rgb(255, 255, 255, 0.9)', fontWeight: '500'}}>{nextEstimatedPayment}</span> </p>}
                { !isSubscriptionActive && <p style={{fontSize: '0.85vw', textAlign: 'center'}}><span style={{color: 'rgb(255, 255, 255, 0.7)', fontWeight: '400'}}> Your subscription has been canceled</span></p>}
            </div>
            <div style={{display: 'flex', marginRight: '0.75vw', marginTop: '2vw'}}>
                <TextButton style={{flex:1, border: '1px solid rgb(255, 255, 255, 0.2)', marginRight: '0.3vw', borderRadius: '0.5vw', fontSize: '0.9vw', fontWeight: 600, color: 'rgb(255, 255, 255, 0.8)'}} onClick={naviagteMicrosoftPortal}>
                    
                    { isSubscriptionActive && 'Change Plan'}
                    { !isSubscriptionActive && 'Re-activate'}
                </TextButton>
                <TextButton  style={{flex:1, border: '1px solid rgb(255, 255, 255, 0.2)', marginLeft: '0.3vw', borderRadius: '0.5vw', fontSize: '0.9vw', fontWeight: 600, color: 'rgb(255, 255, 255, 0.8)'}} onClick={() => {
                    setRedeemCodeShow(true)
                    setRedeemModalStatus('input')
                    setSubscriptionCode('')
                }}>
                    Redeem Code
                </TextButton>
            </div>
        </div>}

        { isLoading && <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh'}}>
            <CircularProgress color="inherit"/>
        </div>}
        </>
    );
}

export default SubscriptionSettings;
