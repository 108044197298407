
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';

import HomeBG from "../../resources/homeBgIm.png";

import SpeciaLogo2 from '../../resources/circle specia 2.png'

import SpeciaLogo from '../../resources/speciaLogo.png'

import SecurityImg from '../../resources/Security.png'

import SpeciaLogoWithName from '../../resources/SpeciaLogoWithName.png'

import BGD from "../../resources/BGD1.png";

import TeamLogo from '../../resources/chart.png'

import PlayButton from '../../resources/playbutton.png'


import Cookies from 'universal-cookie';

import { Button } from 'react-bootstrap';
import { ButtonBase } from '@mui/material';




export default () => {

    const navigate = useNavigate();

    const auth = useAuth()

    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get('token'); 

    const [status, setStatus] = useState(200)

    const [loading, setLoading] = useState(false)

    const cookies = new Cookies();

    const currentPath = cookies.get('currentPath') || '/'


    useEffect(() => {

        if(auth.isAuthenticated){
            console.log(currentPath)
            navigate(currentPath)
        }

        if(token){

            const urlWithToken = new URL(`${window.origin}/api/microsoft/subscription/new`);
            urlWithToken.searchParams.set('token', token);
            setLoading(true)
            fetch(urlWithToken.toString(), {
                method: 'GET'
            }).then(response => {
                setStatus(response.status)
                setLoading(false)
            }).catch((error) => {
                console.error(error);
            });
        }

    }, [auth.isAuthenticated])

    

    var w = window.innerWidth;
    var h = window.innerHeight;
    if (w>h){
        return (
            <>  
    
            {/* <img src={SpeciaLogo} width="12%" style={{padding: '1.2vw'}}/> */}
            
            
            { status != 200 && <div style={{display:'flex', flexDirection:'row', width:'100%', padding:'2vh', height:'100vh'}}>
                    {/* home page */}
                    { !loading && 
                    <>
                        <div style={{flex: 1, width: '100%', display: 'flex', textAlign: 'left', justifyContent:'center', flexDirection:'column', marginLeft: '5vw'}}>
                            <div style={{margin:'3vh', display: 'flex', alignItems: 'center', flex: 1, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'right'}}>
                                <div style={{flex: 1}}> 
                                    <p style={{marginBottom: '0vw', fontWeight: '300', fontSize: '1.5vw'}}>{status == 200?'Welcome to': ''}</p> 
                                    <p style={{fontSize: '3vw'}}>{status == 200?'Specia Platform': status == 401? 'Expired Token': 'Unknown Error'}</p>
                                    <p style={{width: '90%', fontWeight: '300', color: 'rgb(255, 255, 255, 0.7)', fontSize: '0.8vw'}}>
                                        {status == 200?'Digital workers with advanced intelligence streamline industries by managing repetitive tasks, interacting with humans, and providing analytics. Unlike humans, they operate error-free and continuously, enhancing business efficiency.': status == 401? 'We couldn\'t identify this purchase. Reopen this SaaS subscription in the Azure portal or in Microsoft 365 Admin Center and select "Configure Account" or "Manage Account" again.': 'An unknown error has occured while trying to validate your token. Please try again later!'}                    
                                    </p>
                                    {status == 200 && <Button style={{ fontSize:'0.9vw', padding: '0.5vw 1.5vw', marginTop: '1.5vw', borderRadius: '0.2vw'}} onClick={() => {auth.signinRedirect()}}>Sign In</Button>}
                                </div>
                            </div>
    
                        </div>
                        <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <div>
                                <img src={SpeciaLogo2} width="100%" style={{paddingRight: '5vw'}}/> 
                            </div>
                        </div>
                    </>}
                    {loading && <>
                        <div style={{textAlign: 'center', flex: 1, display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </>}
                </div>}
    
                {status == 200 && <div style={{display:'flex', flexDirection:'column', width:'100%', padding:'0', textAlign: 'center', alignItems: 'center', backgroundColor: 'rgba(34,16,65,255)'}}>
                    <div style={{width: '100%', padding: '2vw 4vw', paddingBottom: '4vw', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', 'backgroundImage': `linear-gradient(180deg, rgb(255, 255, 255, 0) 0%, rgba(34,16,65, 1) 100%), linear-gradient(245deg, rgb(17, 10, 34, 0) 0px, rgb(19, 11, 38, 0.95) 100%, rgb(32, 10, 43, 1) 50%), linear-gradient(90deg, rgb(17, 10, 34, 0) 0px, rgb(19, 11, 38, 0.95) 100%, rgb(32, 10, 43, 1) 100%), linear-gradient(0deg, rgb(17, 10, 34, 0) 0px, rgb(34,16,65, 0.9) 100%, rgb(32, 10, 43, 1) 100%), url("${BGD}")`}}>
                        <div style={{width: '100%', backgroundColor: 'rgb(255, 255, 255, 0.0)', display: 'flex', alignItems: 'center', borderRadius: '3vw'}}>
                            <div style={{flex: 1, textAlign: 'left'}}><img src={SpeciaLogoWithName} onClick={() => navigate('/landing')} style={{width: '12vw', marginLeft: '1vw', cursor: 'pointer'}}/></div>
                            <div style={{flex: 3, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', color: 'rgb(255, 255, 255, 0.8)'}}>
                                <ButtonBase onClick={() => navigate('/landing')} style={{margin: '1vw', padding: '0.5vw 0.5vw', borderRadius: '0.4vw', fontSize: '1vw', fontWeight: '500'}}>Home</ButtonBase>
                                <ButtonBase onClick={() => navigate('/solutions')} style={{margin: '1vw', padding: '0.5vw 0.5vw', borderRadius: '0.4vw', fontSize: '1vw', fontWeight: '500'}}>Solutions</ButtonBase>
                                <ButtonBase  onClick={() => navigate('/pricing')} style={{margin: '1vw', padding: '0.5vw 0.5vw', borderRadius: '0.4vw', fontSize: '1vw', fontWeight: '500'}}>Pricing</ButtonBase>
                                <ButtonBase  onClick={() => navigate('/contact')} style={{margin: '1vw', padding: '0.5vw 0.5vw', borderRadius: '0.4vw', fontSize: '1vw', fontWeight: '500'}}>Contact</ButtonBase>
                            </div>
                            <div style={{flex: 1, textAlign: 'right'}}>
                                <Button style={{ fontSize:'0.9vw', padding: '0.5vw 1.5vw',marginRight: '1vw', borderRadius: '0.4vw'}} onClick={() => {auth.signinRedirect()}}>Sign In</Button>
                            </div>
                        </div>
                        <div style={{marginTop: '9vw', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <p style={{fontSize: '2.8vw', fontWeight: '400', color: 'rgb(255, 255, 255, 0.9)', letterSpacing: '0.05vw'}}>Powerful tool for accountants.</p>
                            <p style={{fontWeight: '400', color: 'rgb(255, 255, 255, 0.7)', fontSize: '2.5vw', width: '47vw', marginTop: '0.5vw', lineHeight: '1.8vw', letterSpacing: '0.05vw'}}>
                                Faster. Smarter. Accurate.
                            </p>
                            <p style={{fontWeight: '300', color: 'rgb(255, 255, 255, 0.7)', fontSize: '0.9vw', width: '40vw', marginTop: '3vw', lineHeight: '1.8vw'}}>
                                Specia Accountant is a cloud-based platform designed to streamline and enhance tax reporting for accountants. Leveraging advanced AI technology, it automates ATO report preparation and integrates seamlessly with accounting software, reducing time spent on repetitive tasks and improving accuracy.
                            </p>
                            <Button style={{ fontSize:'0.9vw', padding: '0.5vw 1.5vw', marginTop: '3vw', borderRadius: '0.4vw' }} onClick={() => {auth.signinRedirect()}}>Try out now</Button>
                            {/* <div style={{width: '55vw', height: '30vw', background: 'rgb(0, 0, 0, 0.5)', borderRadius: '1vw', marginTop: '3.5vw', backgroundImage: "url('https://specia.ai/wp-content/uploads/2021/11/campaign-creators-e6n7uoEnYbA-unsplash-1.jpg')", backgroundSize: '100%'}}>
                                <div style={{width: '100%', height: '100%', backgroundColor: 'rgb(0, 0, 0, 0.6)', borderRadius: '1vw', display: 'flex', justifyContent:'center', alignItems:'center'}}>
                                    <img src={PlayButton} style={{filter: "invert(1)", width: '5vw', height: '5vw'}}/>
                                </div>
                            </div> */}
                        </div>   
                    </div>

                    <div style={{width: '100%', padding: '0 2vw'}}>


                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '5vw'}}>
                            <div style={{ display: 'flex', flexDirection: 'row', backgroundColor: 'rgb(255, 255, 255, 0.025)', padding: '2vw', borderRadius: '1vw', alignItems: 'center'}}>
                                <div style={{flex: 2}}>
                                    <img src={TeamLogo} width="100%" style={{padding: '3vw'}}/> 
                                </div>
        
                                <div style={{textAlign: 'left', padding: '1.5vw', flex: 5, paddingLeft: '2vw'}}>
                                    <p style={{fontSize: '1.7vw', fontWeight: '300', color: 'rgb(255, 255, 255, 0.9)'}}>Choosing Specia Accountant</p>
                                    <p style={{fontSize: '0.9vw', lineHeight: '1.6vw', fontWeight: '300', color: 'rgb(255, 255, 255, 0.7)'}}>Specia Accountant is an AI-powered, cloud-based platform designed for accountants to streamline tax reporting and optimize workflows. With seamless integration to ATO, Xero, and MYOB, it automates tedious tasks, saving time and reducing costs. By enhancing client satisfaction and freeing up capacity, Specia Accountant not only drives immediate efficiency but also supports long-term business growth. Elevate your tax management process with a solution that’s precise, scalable, and built to transform the way you work.</p>
                                    <Button style={{ fontSize:'0.9vw', padding: '0.5vw 1.5vw', borderRadius: '0.2vw', marginTop: '0.5vw', marginBottom: '2.5vw'}} onClick={() => {auth.signinRedirect()}}>Learn More</Button>
                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                        <div style={{flex: 1, marginRight: '1vw'}}>
                                            <p style={{fontSize: '0.9vw', color: 'rgb(255, 255, 255, 0.9', fontWeight: '600', marginBottom: '0.3vw'}}>Integration</p>
                                            <p  style={{fontSize: '0.8vw', color: 'rgb(255, 255, 255, 0.8'}}>Connect ATO, Xero, and MYOB seamlessly.</p>
                                        </div>
                                        <div  style={{flex: 1, marginRight: '1vw'}}>
                                            <p style={{fontSize: '0.9vw', color: 'rgb(255, 255, 255, 0.9', fontWeight: '600', marginBottom: '0.3vw'}}>Automation</p>
                                            <p  style={{fontSize: '0.8vw', color: 'rgb(255, 255, 255, 0.8'}}>AI-driven tasks for faster reporting.</p>
                                        </div>
                                        <div  style={{flex: 1, marginRight: '1vw'}}>
                                            <p style={{fontSize: '0.9vw', color: 'rgb(255, 255, 255, 0.9', fontWeight: '600', marginBottom: '0.3vw'}}>Time</p>
                                            <p  style={{fontSize: '0.8vw', color: 'rgb(255, 255, 255, 0.8'}}>Minimize manual work, maximize efficiency.</p>
                                        </div>
                                        <div  style={{flex: 1, marginRight: '1vw'}}>
                                            <p style={{fontSize: '0.9vw', color: 'rgb(255, 255, 255, 0.9', fontWeight: '600', marginBottom: '0.3vw'}}>Savings</p>
                                            <p  style={{fontSize: '0.8vw', color: 'rgb(255, 255, 255, 0.8'}}>Reduce costs with streamlined processes.</p>
                                        </div>
                                        <div  style={{flex: 1, marginRight: '1vw'}}>
                                            <p style={{fontSize: '0.9vw', color: 'rgb(255, 255, 255, 0.9', fontWeight: '600', marginBottom: '0.3vw'}}>Satisfaction</p>
                                            <p  style={{fontSize: '0.8vw', color: 'rgb(255, 255, 255, 0.8'}}>Improve client trust and results.</p>
                                        </div>
        
                                    </div>
                                </div>
                                
                            </div>
                        </div>
    
                    
        
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '5vw'}}>
                            <div style={{ display: 'flex', flexDirection: 'row', borderRadius: '1vw', alignItems: 'center'}}>
        
                                <div style={{textAlign: 'left', flex: 2, paddingRight: '4vw'}}>
                                    <p style={{fontSize: '2.1vw', fontWeight: '300', color: 'rgb(255, 255, 255, 0.9)', marginBottom: '2vw'}}>Efficiency</p>
                                    
                                    <p style={{fontSize: '1.1vw', fontWeight: '400', color: 'rgb(255, 255, 255, 0.9)'}}>Save Time and Reduce Costs with Automated Workflows and Efficient Tax Management</p>
                                    <p style={{fontSize: '0.9vw', lineHeight: '1.6vw', fontWeight: '300', color: 'rgb(255, 255, 255, 0.7)'}}>Specia Accountant helps accountants optimize their workflow by automating repetitive tax tasks, reducing manual labor, and streamlining reporting processes. With advanced AI technology, our platform minimizes time spent on data entry, calculations, and compliance checks, while also lowering operational costs. By enhancing accuracy and efficiency, Specia Accountant allows you to focus on high-value tasks, driving both time and cost savings for your practice.</p>
                                    <Button style={{ fontSize:'0.9vw', padding: '0.5vw 1.5vw', borderRadius: '0.2vw', marginTop: '1.5vw'}} onClick={() => {auth.signinRedirect()}}>Learn More</Button>
                                
                                </div>
                                <div style={{width: '10vw', flex: 2, textAlign: 'right', borderRadius: '1vw'}}>
                                    <img style={{width: '100%', height: '27vw', borderRadius: '1vw'}} src="https://specia.ai/wp-content/uploads/2021/11/icons8-team-yTwXpLO5HAA-unsplash.jpg" />
                                </div>
                            </div>
                        </div>

                        <div style={{marginTop: '2vw', width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '5vw'}}>
                            <div style={{ display: 'flex', flexDirection: 'row', backgroundColor: 'rgb(255, 255, 255, 0.025)', padding: '2vw', borderRadius: '1vw', alignItems: 'center'}}>
                                <div style={{flex: 2}}>
                                    <img src={SpeciaLogo2} width="100%" style={{padding: '3vw', objectFit: 'cover'}}/> 
                                </div>
        
                                <div style={{textAlign: 'left', padding: '1.5vw', flex: 5, paddingLeft: '2vw'}}>
                                    <p style={{fontSize: '1.7vw', fontWeight: '300', color: 'rgb(255, 255, 255, 0.9)'}}>Experience Effortless Tax Management with Powerful Integrations to ATO, Xero, and MYOB</p>
                                    <p style={{fontSize: '0.9vw', lineHeight: '1.6vw', fontWeight: '300', color: 'rgb(255, 255, 255, 0.7)'}}>Specia Accountant connects seamlessly with ATO, Xero, and MYOB, giving accountants a unified platform for streamlined tax reporting and compliance. With real-time data syncing and reliable API integrations, our platform automates workflows across the tools you already use, ensuring accuracy, efficiency, and up-to-date reporting. Eliminate manual data entry and discover a fully connected solution for all your tax needs.</p>
                                </div>
                                <div style={{width: '10vw', flex: 2}}>
                                    <Button style={{ fontSize:'0.9vw', padding: '0.5vw 1.5vw', borderRadius: '0.2vw'}} onClick={() => {auth.signinRedirect()}}>Try Now</Button>
                                </div>
                            </div>
                        </div>
    
        
        
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '5vw'}}>
                            <div style={{ display: 'flex', flexDirection: 'row', borderRadius: '1vw', alignItems: 'center'}}>
        
                                <div style={{width: '10vw', flex: 2, textAlign: 'right', borderRadius: '1vw'}}>
                                    <img style={{width: '100%', height: '27vw', borderRadius: '1vw', objectFit: 'cover'}} src={SecurityImg} />
                                </div>
                                <div style={{textAlign: 'left', flex: 2, paddingLeft: '4vw'}}>
                                    <p style={{fontSize: '2.1vw', fontWeight: '300', color: 'rgb(255, 255, 255, 0.9)', marginBottom: '2vw'}}>Data Security</p>
                                    
                                    <p style={{fontSize: '1.1vw', fontWeight: '400', color: 'rgb(255, 255, 255, 0.9)'}}>Advanced Security Features Ensure Your Data is Encrypted, Private, and Fully Protected at All Times</p>
                                    <p style={{fontSize: '0.9vw', lineHeight: '1.6vw', fontWeight: '300', color: 'rgb(255, 255, 255, 0.7)'}}>Specia Accountant prioritizes the security and confidentiality of your data with industry-leading encryption, multi-factor authentication, and regular compliance audits. Our platform is built with advanced security protocols to safeguard sensitive tax information and ensure that your data remains private and protected at all times. With Specia Accountant, you can focus on your work, knowing that your data is secure against threats and fully compliant with the highest standards of data protection.</p>
                                    <Button style={{ fontSize:'0.9vw', padding: '0.5vw 1.5vw', borderRadius: '0.2vw', marginTop: '1.5vw'}} onClick={() => {auth.signinRedirect()}}>Learn More</Button>
                                
                                </div>
                            
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">

				<footer style={{display: 'flex', flexDirection: 'column', fontSize: '1vw', marginTop: '8vw'}} id="colophon">

				    <div style={{display: 'flex', flexDirection: 'row', alignSelf: 'center', marginBottom: '1vw'}} >
                        
                            <ButtonBase style={{padding: '0.5vw', fontWeight: '500', margin: '0.5vw', borderRadius: '0.4vw'}} >Solutions</ButtonBase>
                            <ButtonBase style={{padding: '0.5vw', fontWeight: '500', margin: '0.5vw', borderRadius: '0.4vw'}} >Pricing</ButtonBase>
                            <ButtonBase style={{padding: '0.5vw', fontWeight: '500', margin: '0.5vw', borderRadius: '0.4vw'}} >Contact Us</ButtonBase>
                    </div> 	

                    <div style={{marginBottom: '3vw', color: 'rgb(255, 255, 255, 0.7)'}}>	© 2024 Specia | All Rights Reserved | <a>Privacy Policy</a>	</div>

                    <div style={{height: '12vw', overflow: 'hidden'}} class="huge-circle">
                        <img style={{width: '30vw'}} src={SpeciaLogo} alt="Specia"/>
                    </div>

                </footer>

			</div>
                    
                </div>}
    
            </>
        )
    }else{
        return(
            <>  
    
    {/* <img src={SpeciaLogo} width="12%" style={{padding: '1.2vw'}}/> */}
    
    
    { status != 200 && <div style={{display:'flex', flexDirection:'row', width:'100%', padding:'2vh', height:'100vh'}}>
            {/* home page */}
            { !loading && 
            <>
                <div style={{flex: 1, width: '100%', display: 'flex', textAlign: 'left', justifyContent:'center', flexDirection:'column', marginLeft: '5vw'}}>
                    <div style={{margin:'3vh', display: 'flex', alignItems: 'center', flex: 1, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'right'}}>
                        <div style={{flex: 1}}> 
                            <p style={{marginBottom: '0vw', fontWeight: '300', fontSize: '1.5vw'}}>{status == 200?'Welcome to': ''}</p> 
                            <p style={{fontSize: '3vw'}}>{status == 200?'Specia Platform': status == 401? 'Expired Token': 'Unknown Error'}</p>
                            <p style={{width: '90%', fontWeight: '300', color: 'rgb(255, 255, 255, 0.7)', fontSize: '0.8vw'}}>
                                {status == 200?'Digital workers with advanced intelligence streamline industries by managing repetitive tasks, interacting with humans, and providing analytics. Unlike humans, they operate error-free and continuously, enhancing business efficiency.': status == 401? 'We couldn\'t identify this purchase. Reopen this SaaS subscription in the Azure portal or in Microsoft 365 Admin Center and select "Configure Account" or "Manage Account" again.': 'An unknown error has occured while trying to validate your token. Please try again later!'}                    
                            </p>
                            {status == 200 && <Button style={{ fontSize:'0.9vw', padding: '0.5vw 1.5vw', marginTop: '1.5vw', borderRadius: '0.2vw'}} onClick={() => {auth.signinRedirect()}}>Sign In</Button>}
                        </div>
                    </div>

                </div>
                <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div>
                        <img src={SpeciaLogo2} width="100%" style={{paddingRight: '5vw'}}/> 
                    </div>
                </div>
            </>}
            {loading && <>
                <div style={{textAlign: 'center', flex: 1, display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </>}
        </div>}

        {status == 200 && <div style={{display:'flex', flexDirection:'column', width:'100%', textAlign: 'center', alignItems: 'center'}}>
            <div style={{ marginTop: '8vw', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <p style={{fontSize: '11.5vw', fontWeight: '300', color: 'rgb(255, 255, 255, 0.9)', letterSpacing: '0.2vw'}}>Specia Is The Future</p>
                <p style={{fontWeight: '200', color: 'rgb(255, 182, 193)', fontSize: '3.8vw', width: '90vw', marginTop: '12vw', lineHeight: '7vw'}}>
                    Digital workers with advanced intelligence streamline industries by managing repetitive tasks, interacting with humans, and providing analytics. Unlike humans, they operate error-free and continuously, enhancing business efficiency.
                </p>
                <Button style={{ fontSize:'4vw', padding: '1.5vw 4.5vw', marginTop: '5vw', borderRadius: '0.2vw'}} onClick={() => {auth.signinRedirect()}}>Sign In</Button>
                <div style={{width: '100vw', height: '56.5vw', background: 'rgb(0, 0, 0, 0.5)', borderRadius: '1vw', marginTop: '18vw', backgroundImage: "url('https://specia.ai/wp-content/uploads/2021/11/campaign-creators-e6n7uoEnYbA-unsplash-1.jpg')", backgroundSize: '100%'}}>
                    <div style={{width: '100%', height: '100%', backgroundColor: 'rgb(0, 0, 0, 0.6)', borderRadius: '1vw', display: 'flex', justifyContent:'center', alignItems:'center'}}>
                        <img src={PlayButton} style={{filter: "invert(1)", width: '5vw', height: '5vw'}}/>
                    </div>
                    {/* <iframe style={{width: '100%', height: '100%'}} src="https://www.youtube.com/embed/TdQn_lnco7Y?si=hsBhSmgE4jsf9Li-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
                </div>
            </div>   

            <div style={{marginTop: '10vw', width: '95%', display: 'flex', flexDirection: 'row', alignItems: 'left', padding: 'vw', paddingTop: '10vw'}}>
                <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'rgb(255, 255, 255, 0.05)', padding: '2vw', borderRadius: '5vw', alignItems: 'left'}}>
                    <div style={{flex: 2, textAlign: 'center'}}>
                        <img src={SpeciaLogo2} width="45%" style={{padding: '1.5vw', objectFit: 'cover', marginTop: '10vw'}}/> 
                    </div>

                    <div style={{textAlign: 'center', padding: 'vw', flex: 5, paddingLeft: 'vw', paddingTop:'5vw'}}>
                        <p style={{fontSize: '4vw', fontWeight: '300', color: 'rgb(255, 182, 193)', paddingTop:'5vw'}}>Your new virtual recruit will soon become an integral and indispensable part of your team</p>
                        <p style={{fontSize: '3.5vw', lineHeight: '6vw', fontWeight: '10', color: 'white', paddingTop:'6vw', paddingBottom:'6vw'}}>Specia digital workers can be deployed across a range of industries and have already proven to be highly valuable additions to major organisations. They can interact with human colleagues and customers, perform assigned tasks, read emails, process attachments, extract data, supplement business rules, input data, supply analytics, report metrics and more.</p>
                    </div>
                    <div style={{flex: 2, textAlign:'center'}}>
                        <Button style={{ fontSize:'3.5vw', padding: '2vw 2vw', borderRadius: '0.2vw', marginTop: '1.5vw', marginBottom: '10vw'}} onClick={() => {auth.signinRedirect()}}>Try now</Button>
                    </div>
                </div>
            </div>

            <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '5vw', paddingTop: '15vw'}}>
                <div style={{ display: 'flex', flexDirection: 'column', borderRadius: '1vw', alignItems: 'center'}}>

                    <div style={{textAlign: 'center', flex: 2, paddingRight: '0vw', paddingTop:'5vw'}}>
                        <p style={{fontSize: '8vw', fontWeight: '300', color: 'rgb(211, 211, 211)'}}>Gayatri</p>
                        <img style={{width: '80%', borderRadius: '1vw', paddingTop:'3.5vw'}} src="https://specia.ai/wp-content/uploads/2021/11/icons8-team-yTwXpLO5HAA-unsplash.jpg" />
                        <p style={{paddingTop: '10vw',fontSize: '4vw', fontWeight: '400', color: 'rgb(255, 182, 193)'}}>Your new virtual recruit will soon become an integral and indispensable part of your team</p>
                        <p style={{fontSize: '3vw', lineHeight: '5vw', fontWeight: '300', color: 'white', paddingTop:'4vw', paddingBottom: '5vw'}}>Specia digital workers can be deployed across a range of industries and have already proven to be highly valuable additions to major organisations. They can interact with human colleagues and customers, perform assigned tasks, read emails, process attachments, extract data, supplement business rules, input data, supply analytics, report metrics and more.</p>
                        <Button style={{ fontSize:'3.5vw', padding: '2vw 2vw', borderRadius: '0.2vw', marginTop: '1.5vw', marginBottom: '8vw'}} onClick={() => {auth.signinRedirect()}}>Learn More</Button>
                    
                    </div>
    
                </div>
            </div>

            <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '0vw'}}>
                <div style={{ display: 'flex', flexDirection: 'row', backgroundColor: 'rgb(255, 255, 255, 0.025)', padding: '0vw', borderRadius: '0vw', alignItems: 'center'}}>
                    <div style={{textAlign: 'center', flex: 5, paddingLeft: '0vw'}}>
                        <p style={{fontSize: '6vw', fontWeight: '300', color: 'rgb(255, 255, 255, 0.9)', paddingTop:'15vw', paddingTop: '25vw'}}>Incremental pricing model</p>
                        <p style={{padding: '2vw', paddingTop:'7.5vw', fontSize: '3.2vw', lineHeight: '6vw', fontWeight: '300', color: 'rgb(255, 182, 193)'}}>Specia digital workers can be deployed across a range of industries and have already proven to be highly valuable additions to major organisations. They can interact with human colleagues and customers, perform assigned tasks, read emails, process attachments, extract data, supplement business rules, input data, supply analytics, report metrics and more.</p>
                        <img src={TeamLogo} width="80%" style={{padding: '3vw'}}/> 
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <div style={{flex: 2, marginRight: '5vw'}}>
                                <p style={{fontSize: '3.5vw', color: 'rgb(255, 255, 255, 0.9', fontWeight: '600', marginBottom: '0.3vw', paddingTop: '5vw', marginLeft:'5vw'}}>Free</p>
                                <p  style={{fontSize: '2.3vw', color: 'rgb(255, 255, 255, 0.8', marginLeft:'5vw'}}>Start exploring the platform</p>
                            </div>
                            <div  style={{flex: 1, marginRight: '5vw'}}>
                                <p style={{fontSize: '3.5vw', color: 'rgb(255, 255, 255, 0.9', fontWeight: '600', marginBottom: '0.3vw', paddingTop: '5vw'}}>Small</p>
                                <p  style={{fontSize: '2.3vw', color: 'rgb(255, 255, 255, 0.8'}}>Expand your capabilities for you business</p>
                            </div>
                            <div  style={{flex: 1, marginRight: '5vw'}}>
                                <p style={{fontSize: '3.5vw', color: 'rgb(255, 255, 255, 0.9', fontWeight: '600', marginBottom: '0.3vw', paddingTop: '5vw'}}>Medium</p>
                                <p  style={{fontSize: '2.3vw', color: 'rgb(255, 255, 255, 0.8'}}>Scale your operations confidently</p>
                            </div>
                            <div  style={{flex: 1, marginRight: '5vw'}}>
                                <p style={{fontSize: '3.5vw', color: 'rgb(255, 255, 255, 0.9', fontWeight: '600', marginBottom: '0.3vw', paddingTop: '5vw'}}>Large</p>
                                <p  style={{fontSize: '2.3vw', color: 'rgb(255, 255, 255, 0.8'}}>Streamline complex workflows</p>
                            </div>
                            <div  style={{flex: 1, marginRight: '5vw'}}>
                                <p style={{fontSize: '3.5vw', color: 'rgb(255, 255, 255, 0.9', fontWeight: '600', marginBottom: '0.3vw', paddingTop: '5vw'}}>Corporate</p>
                                <p  style={{fontSize: '2.3vw', color: 'rgb(255, 255, 255, 0.8'}}>Unlimited access for your organization's extensive needs</p>
                            </div>

                        </div>
                        <Button style={{ fontSize:'3.5vw', padding: '2vw 2vw', borderRadius: '0.2vw', marginTop: '1.5vw', marginBottom: '10vw'}} onClick={() => {auth.signinRedirect()}}>Learn More</Button>
                    </div>
                    
                </div>
            </div>


            <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '5vw'}}>
                <div style={{ display: 'flex', flexDirection: 'row', borderRadius: '1vw', alignItems: 'center'}}>
                    <div style={{textAlign: 'center', flex: 2, paddingLeft: '4vw'}}>
                        <p style={{fontSize: '8vw', fontWeight: '300', color: 'rgb(255, 255, 255, 0.9),', alignItems: 'center', paddingTop: '25vw'}}>Scout</p>
                        <img style={{alignItems: 'center',width: '80%', borderRadius: '1vw', objectFit: 'cover', paddingTop: '6vw'}} src={SecurityImg} />
                        <p style={{fontSize: '4vw', fontWeight: '400', color: 'rgb(255, 182, 193)', paddingTop: '10vw'}}>Your new virtual recruit will soon become an integral and indispensable part of your team</p>
                        <p style={{fontSize: '3vw', lineHeight: '5vw', fontWeight: '300', color: 'white', paddingTop: '6vw', paddingBottom: '6vw'}}>Specia digital workers can be deployed across a range of industries and have already proven to be highly valuable additions to major organisations. They can interact with human colleagues and customers, perform assigned tasks, read emails, process attachments, extract data, supplement business rules, input data, supply analytics, report metrics and more.</p>
                        <Button style={{ fontSize:'3.5vw', padding: '2vw 2vw', borderRadius: '0.2vw', marginTop: '1.5vw', marginBottom: '10vw'}} onClick={() => {auth.signinRedirect()}}>Learn More</Button>
                    
                    </div>
                   
                </div>
            </div>
            
        </div>}

    </>
        )
    }
    }
        
    