import { useEffect, useState } from "react"
import { Button, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap"
import { useAuth } from "react-oidc-context"
import UserTable from "./UserTable"
import DetailModal from "./DetailModal"
import { IconButton, createTheme, ThemeProvider } from "@mui/material"

import SearchIcon from '@mui/icons-material/Search';
import SessionExpiredModal from "../../../utils/SessionExpiredModal"

import { TextButton, darkTheme } from "../../../utils/funtions"
import AddUserModal from "./AddUserModal"



export default (props) => {

    const [userData, setUserData] = useState([])

    const [availableUserData, setAvailableUserData] = useState([])

    const [detailModalShown, setDetailModalShown] = useState(false)

    const [currentUser, setCurrentUser] = useState('')

    const [addUserShow, setAddUserShow] = useState(false)

    const [isLoading, setIsLoading] = useState(true)

    const [searchQuery, setSearchQuery] = useState('');

    const [sessionExpired, setSessionExpired] = useState(false)

    const auth = useAuth()


    function openModalHandler(id, firstName, lastName, email, role){
        setDetailModalShown(true)
        setCurrentUser({id, firstName, lastName, email, role})
    }

    function closeDetailModalHandler(){
        setDetailModalShown(false)
        // setCurrentUser({})
    }

    function fetchData(){
        if (auth.user?.profile.accountant.ssid){
            console.log('execute')
            fetch(`${window.origin}/api/reporter/users`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${auth.user?.access_token}`
                }
            }).then(response => {
                if(response.status === 403){
                    setSessionExpired(true)
                    throw new Error('Session expired');
                }else{
                    return response.json()
                }
            }).then(data => {
                setUserData(data.filter(item => item.email !== auth.user?.profile.email))
            }).catch((error) => {
                console.error(error);
            });
            fetch(`${window.origin}/api/reporter/available-users`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${auth.user?.access_token}`
                }
            }).then(response => {
                if(response.status === 403){
                    setSessionExpired(true)
                    throw new Error('Session expired');
                }else{
                    return response.json()
                }
            }).then(data => {
                console.log(data)
                setAvailableUserData(data.filter(item => item.email !== auth.user?.profile.email))
                // setIsLoading(false)
            }).catch((error) => {
                console.error(error);
            });
        }
    }

    function refreshHandler(){
        fetchData()
    }

    useEffect(()=> {
        if (auth.user?.profile.accountant.ssid){
            console.log('execute')
            fetch(`${window.origin}/api/reporter/users`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${auth.user?.access_token}`
                }
            }).then(response => {
                if(response.status === 403){
                    setSessionExpired(true)
                    throw new Error('Session expired');
                }else{
                    return response.json()
                }
            }).then(data => {
                setUserData(data.filter(item => item.email !== auth.user?.profile.email))
                setIsLoading(false)
            }).catch((error) => {
                console.error(error);
            });
            fetch(`${window.origin}/api/reporter/available-users`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${auth.user?.access_token}`
                }
            }).then(response => {
                if(response.status === 403){
                    setSessionExpired(true)
                    throw new Error('Session expired');
                }else{
                    return response.json()
                }
            }).then(data => {
                console.log(data)
                setAvailableUserData(data.filter(item => item.email !== auth.user?.profile.email))
                // setIsLoading(false)
            }).catch((error) => {
                console.error(error);
            });
        }
    }, [auth.user?.profile.accountant.ssid])

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredUserData = userData.filter(user =>
        user.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.lastName.toLowerCase().includes(searchQuery.toLowerCase())
    );


    return (
        <>  
            <SessionExpiredModal show={sessionExpired}/>

            <AddUserModal show={addUserShow} onClose={() => {setAddUserShow(false)}} availableUsers={availableUserData} onRefresh={refreshHandler}/>
            <>
                <div style={{display: 'flex', textAlign: 'left', marginBottom: '0.5vw', width: '15vw', marginBottom: '1.5vw', flexDirection: 'row', width: '100%'}}>
                        <div style={{backgroundColor: 'rgb(255, 255, 255, 0.08)', borderRadius: '50px', padding: '0.2vw'}}>
                            <ThemeProvider theme={darkTheme}>

                                <IconButton aria-label="delete" style={{backgroundColor: 'rgb(255, 255, 255, 0.15)', padding: '0.5vw', fontSize: '0.5vw', color: 'rgb(255, 255, 255, 0.7)', cursor: 'none'}}>
                                    <SearchIcon style={{fontSize: '1vw'}}/>
                                </IconButton>
                            </ThemeProvider>
                            <Form.Control 
                                style={{
                                    display: 'inline',  backgroundColor: 'rgba(255,255,255,0)', padding: 0, paddingLeft: '0.8vw', paddingRight: '0.8vw',
                                    border: '1px solid rgb(255, 255, 255, 0)', borderRadius: '6px', fontSize: '0.9vw', maxWidth: '80%', marginLeft:'0' , fontWeight: '200'
                                }} 
                                className='shadow-none'
                                type="text" 
                                placeholder="Search" 
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </div>
                        <div style={{ flex: 1, textAlign: 'right', paddingLeft: '0.5vw'}}>
                    
                            <OverlayTrigger
                                key={'top-submit'}
                                placement={'top'}
                                overlay={
                                    <Tooltip id={`tooltip-submit`}>
                                        Add User
                                    </Tooltip>
                                }
                                >
                                    <TextButton onClick={() => {setAddUserShow(true)}} variant='text' style={{ fontSize: '0.9vw',cursor: 'pointer', marginLeft: '0.5vw', padding: '0.75vw', minWidth: 0, borderRadius: '2vw', backgroundColor: 'rgb(255, 255, 255, 0.1)', marginLeft: '1vw'}} > 
                                        <i className="fa-solid fa-plus" style={{fontSize: '0.9vw', width: '0.95vw', height: '0.95vw'}}></i>
                                    </TextButton>
                                
                            </OverlayTrigger>
                                {/* <span style={{ fontSize: '0.9vw', paddingLeft: '1.8vw' }} onClick={() => setInviteModalShown(true)}><i className="fa-solid fa-plus" style={{ paddingRight: '8px', fontSize: '0.9vw' }}></i>Add</span>
                                <span style={{ fontSize: '0.9vw', paddingLeft: '1.8vw' }} onClick={() => setUploadModalShow(true)}><i className="fa-solid fa-arrow-up" style={{ paddingRight: '8px', fontSize: '0.9vw' }}></i>Upload</span> */}
                    </div>
                </div>

                <Modal.Body style={{margin: '0.5vw 0', marginTop: '0', padding: 0, fontSize: '1.6rem', borderRadius: '20px'}}>
                    <div style={{width: '100%', textAlign: 'left'}}>
                        <UserTable userData={filteredUserData} openModalHandler={openModalHandler} isLoading={isLoading}/>
                    </div>
                </Modal.Body>

            </>
            <DetailModal show={detailModalShown} onClose={closeDetailModalHandler} user={currentUser} onRefresh={refreshHandler}/>
        </>
    )
}