import {
  Outlet, 
  Link,
  useLocation,
  NavLink
} from "react-router-dom";
import React, {
  Suspense, useEffect,
} from 'react';
import { 
  useNavigate, 
  // useSearchParams,
} from "react-router-dom";

import styles from './Layout.module.css'

import { useAuth } from "react-oidc-context";

import { useState } from 'react'

import { setStep, setTier, setRole } from '../../app/stepSlice'

import { motion } from "framer-motion";

import { useDispatch, useSelector } from "react-redux";

import MenuBar from "../../utils/MenuBar";
import SetUpRequired from "./SetUpRequired";
import QuickAccessBar from "../../utils/QuickAccessBar";
import TransparentButton from "../../utils/TransparentButton";
import AccessRequired from "./AccessRequired";



const pageVariants = {
  initial: {
    opacity: 0
  },
  in: {
    opacity: 1
  },
  out: {
    opacity: 0
  }
};

const pageTransition = {
  type: "tween",
  ease: "linear",
  duration: 0.15
};


function Layout() {

  const auth = useAuth();

  const location = useLocation()

  const navigate = useNavigate()

  const [colorChange, setColorchange] = useState(false);

  const role = useSelector((state) => state.step.role)

  const allSteps = useSelector((state) => state.step.steps[state.step.role])

  const dispatch = useDispatch()

  let navChangedColorHeight;

  if(location.pathname == '/'){
    navChangedColorHeight = window.innerHeight
  }else{
    navChangedColorHeight = window.innerHeight/2
  }

  const changeNavbarColor = (scrollTop) =>{
      if(scrollTop>0){
        setColorchange(true);
      }
      else{
        setColorchange(false);
      }
  };

  let scroller = document.querySelector("#scroller")
  scroller?.addEventListener("scroll", (event) => {
    changeNavbarColor(scroller.scrollTop)
  });


  useEffect( () => {
    let newRole

    // console.log(auth.user?.access_token)

    if(auth.user?.profile.groups.includes("client-admin")){
      newRole = 'owner'
      dispatch(setRole('owner'))
    }else if (auth.user?.profile.groups.includes("client-manager")){
      newRole = 'manager'
      dispatch(setRole('manager'))
    }else{
      newRole = 'user'
      dispatch(setRole('user'))
    }
    
    // console.log(auth.user?.profile.accountant.ssid)

    if(auth.user?.profile.accountant.ssid){
      fetch(`${window.origin}/api/company`, {
          headers: {
              Authorization: `Bearer ${auth.user?.access_token}`,
          },
      }).then(response => {
        if(response.status === 403){
          auth.signinRedirect()
        }else{
          return response.json()
        }
      }
      ).then(data => {
        console.log(data)
          if(data.subscription_type == 'hired'){
              dispatch(setTier('P'))
          }else if (data.subscription_type == 'free'){
              dispatch(setTier('F'))
          }
      }).catch((error) => {
        console.error(error);
    });
    }
      
  }, [allSteps, role])

  return (
    <div style={{ textAlign: 'left', position: 'relative', height: '100vh', display: 'flex', flexDirection: 'row' }}>
      {/* <MenuBar /> */}

      <AccessRequired>

        <SetUpRequired>

          <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100vh' }}>
            <>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                <div style={{ flex: 1, display: "flex", flexDirection: "column", height: '6vw' }}>
                  <div style={{flex: 2, marginLeft: '2vw', marginRight: '2vw', marginTop: '2.5vh'}} >
                    <p style={{fontSize: '1.8vw', marginBottom: '0.4vw'}}>Gayatri</p>
                    <p style={{fontSize: '0.75vw', color: 'rgb(255, 255, 255, 0.7)'}}>Discover the future with precision automation</p>
                  </div>
                </div>
                <div style={{flex: 1, fontSize: '1.8vh', textAlign: 'center', flexDirection: 'row', marginLeft: '1.8vw', marginRight: '2vw', paddingTop: '2.5vw'}}>
                      <div style={{display: 'inline-block', backgroundColor: 'rgb(255, 255, 255, 0.1)', borderRadius: '2vw', padding: '0.2vw'}}>
                        <span style={{}}><TransparentButton id="about-tab" onClick={() => {navigate('/mydigitalworkers/gayatri/about')}} selected={location.pathname.includes("about")}>Information</TransparentButton></span>
                        <span style={{}}><TransparentButton id="workpaper-tab" onClick={() => {navigate('/mydigitalworkers/gayatri/workpapers')}} selected={location.pathname.includes("workpapers")}>Workpapers</TransparentButton></span>
                        <span style={{}}><TransparentButton id="clients-tab" onClick={() => {navigate('/mydigitalworkers/gayatri/clients')}} selected={location.pathname.includes("clients")}>Clients</TransparentButton></span>
                        { auth.user?.profile.groups.includes("gayatri_admin") && <span style={{}}><TransparentButton id="settings-tab" onClick={() => {navigate('/mydigitalworkers/gayatri/settings')}} selected={location.pathname.includes("settings")}>Settings</TransparentButton></span>}
                      </div>
                </div>
                <div style={{paddingTop: '1.5vw', paddingRight: '1.5vw', flex: 1, display: 'flex', alignItems: 'flex-start'}}>
                  <div style={{flex: 1, textAlign: 'right'}}>
                    <QuickAccessBar/>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "column", flex: 1, height: 'calc(100vh - 6vw)'}}>
                <div className='mainContainer' style={{ flex: 1, display: 'flex', overflow: 'auto'}}>
                  <div style={{flex: 1, width: '100%'}}>
                    <div style={{height: "calc(100vh - 6vw)", overflowY: 'auto'}}>
                      <Suspense>
                        <motion.div
                            key={location.pathname}
                            initial="initial"
                            animate="in"
                            variants={pageVariants}
                            transition={pageTransition}
                          >
                          <Outlet />
                        </motion.div>
                      </Suspense>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        
        </SetUpRequired>

      </AccessRequired>
    </div>
  )
}

export default Layout;