import Modal from 'react-bootstrap/Modal';
import { HotTable } from '@handsontable/react';
import React, { useState, useEffect } from 'react';
import { Button, Table } from 'react-bootstrap';
import * as XLSX from 'xlsx';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import FolderOpenIcon from '@mui/icons-material/FolderOpen';

import download from 'downloadjs'

import 'handsontable/dist/handsontable.full.min.css';

import Handsontable from 'handsontable/base';
import { registerAllModules } from 'handsontable/registry';
import { Accordion, AccordionDetails, AccordionSummary, Chip, CircularProgress, IconButton, Menu, MenuItem, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider, Typography, createTheme } from '@mui/material';
import { useAuth } from 'react-oidc-context';
import SessionExpiredModal from '../../../utils/SessionExpiredModal';

registerAllModules();

const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });



function formatABN(abn) {



    if(abn == 'NOT PROVIDED'){
        return abn
    }

    // Remove any non-digit characters in case the input is not clean
    let str = abn.replace(/\D/g, '');

    // Check if the input is exactly 11 digits, if not, handle as needed
    if (str.length !== 11) {
        console.error("Invalid ABN: ABN must be exactly 11 digits");
        return null;
    }

    // Insert spaces to format as ABN
    return str.replace(/(\d{2})(\d{3})(\d{3})(\d{3})/, '$1 $2 $3 $4');
}


export default ({show, selectedGroup, onHide, downloadHandler, editHandler, replicateHandler, isUpdating, onBackdropOpen, onBackdropClose, onShow}) => {

    const [isLoading, setIsLoading] = useState(false)

    const [currentRequestId, setCurrentRequestId] = useState(-1)

    const [currentSubVersion, setCurrentSubVersion] = useState(-1)

    const [versionData,  setVersionData] = useState([])

    const auth = useAuth()

    const [expanded, setExpanded] = React.useState('panel1');

    const [sessionExpired, setSessionExpired] = useState(false)

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event, requestId, subVersion) => {
        setCurrentRequestId(requestId)
        setCurrentSubVersion(subVersion)
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    async function downloadActivityStatement(clientName, clientABN, startDate, endDate){

        onBackdropOpen()

        let period = `${new Date(startDate).toLocaleString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })} to ${new Date(endDate).toLocaleString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })}`

        try{
            let response = await fetch(`${window.origin}/api/reporter/reports`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${auth.user?.access_token}`,
                },
                body: JSON.stringify({
                    startDate: startDate,
                    endDate: endDate,
                    clients: [
                        {
                            abn: clientABN,
                            client_name:clientName
                        }
                    ]
                }),
            })
            const json_resp = await response.json()

            console.log(json_resp)
            

            if(response.status === 403){
                setSessionExpired(true);
                throw new Error('Session expired');
            }

            response = await fetch(`${window.location.origin}/download/reports`,
                { 
                    method: "POST",
                    headers: { 
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.user?.access_token}`
                    },
                    body: JSON.stringify({
                        ...json_resp,
                        request: {
                            startDate: startDate,
                            endDate: endDate,
                            clients: [{abn: clientABN, client_name: clientName}]
                        }
                    })
                })

            const blob_response = await response.blob()
            

            await download(blob_response, `ActivityStatements_${clientName}_${period}.zip`)

            onBackdropClose()


        }catch(e){

        }
    }


    function reload(){
        // onBackdropOpen()
        setIsLoading(true)
        fetch(`${window.origin}/api/creator/request-groups/details/${selectedGroup.clientId}/${selectedGroup.startDate}/${selectedGroup.endDate}`, {
            headers: {
                "Authorization": `Bearer ${auth.user?.access_token}`,
                'Cache-Control': 'no-cache',
                'If-None-Match': '0',
            }
        })
        .then(response => {
            if(response.status === 403){
                setSessionExpired(true)
                throw new Error('Session expired');
            } else {
                return response.json();
            }
        })
        .then(data => {
            console.log(data)
            setVersionData(data)
            if(selectedGroup.version == -1){
                setExpanded(data[0].version)
            }else{
                setExpanded(selectedGroup.version)
            }
            // onBackdropClose()
            setIsLoading(false)
            
        })
        .catch(error => {
            console.error('Error fetching data:', error);
            // onBackdropClose()
            setIsLoading(false)
        })
        .finally(() => {
            
        });
    }

    useEffect(() => {

        console.log(selectedGroup)


        
        if(selectedGroup.clientId != -1 && !isUpdating){
            reload()
        }

        
        
        
    }, [selectedGroup, isUpdating])

    return (
        <Modal
            show={show}
            onHide={onHide}
            dialogClassName="modal-70w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton style={{ padding: '0.3rem 1rem', position: 'relative', borderWidth: '0px' }}>
            </Modal.Header>
            <Modal.Body>
                <div style={{display: 'flex',flexDirection: 'row', alignItems: 'center'}}>
                    <div>
                        <FolderOpenIcon style={{margin: '1.3vw', marginRight: '3vw', fontSize: '5vw', color: 'rgb(255, 255, 255, 0.5)'}}/>
                    </div>
                    <div style={{flex: 1, padding: '0.5vw', paddingLeft: '0'}}>
                        <p style={{fontSize: '0.9vw', margin: 0, lineHeight: '1.5vw', fontWeight: '600', color: 'rgb(255, 255, 255, 0.9)'}}>{selectedGroup.clientName}</p>
                        <p style={{fontSize: '0.75vw', lineHeight: '1.5vw', margin: 0, fontWeight: '400', color: 'rgb(255, 255, 255, 0.7)'}}>{formatABN(selectedGroup.clientAbn)}</p>
                        <p style={{fontSize: '0.75vw', lineHeight: '1.5vw', margin: 0, fontWeight: '500', color: 'rgb(255, 255, 255, 0.8)'}}>{`${new Date(selectedGroup.startDate).toLocaleString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })} to ${new Date(selectedGroup.endDate).toLocaleString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })}`}</p>
                    </div>
                </div>


                { !isLoading && <div style={{margin: '1.3vw'}}>
                    <p style={{fontSize: '1.2vw'}}>Versions</p>
                    <div>

                        {versionData.map((version, index) => {
                            const requestId = version.request_id
                            return (
                                <Accordion key={index} expanded={expanded === version.version} onChange={handleChange(version.version)} style={{margin: 0}}>
                                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" style={{margin: 0}}>
                                        <div style={{display: 'flex', flexDirection: 'row', width: '100%', margin: 0}}>
                                            <div style={{flex: 1}}>
                                                <p style={{margin: 0, fontSize: '0.9vw', fontWeight: '500'}}>Version {version.version}</p>
                                            </div>
                                            <div>
                                                <ThemeProvider theme={darkTheme}>
                                                    <Chip size="small" style={{fontSize: '0.6vw', lineHeight: '1vw', padding: '0 0.1vw', height:'1.5vw', backgroundColor: version.status == 'done'? 'rgb(25, 191, 6, 0.2)': (version.status == 'ctlreq'? "SUBMITTED": (version.status == 'processing'? "PROCESSING": 'rgb(191, 6, 12, 0.2)')), color: 'white'}} label={version.status == 'done'? 'SUCCESS': (version.status == 'ctlreq'? "SUBMITTED": (version.status == 'processing'? "PROCESSING": "FAILED"))}/>
                                                </ThemeProvider>
                                            </div>
                                        </div>
                                    
                                    </AccordionSummary>
                                    { version.status == 'done' && <AccordionDetails>

                        
                                    
                                
                                        <TableContainer component={Paper} style={{backgroundColor: 'transparent'}}>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead style={{backgroundColor: 'transparent'}}>
                                                <TableRow>
                                                    <TableCell style={{border: 'none', fontSize: '0.8vw'}}>Version</TableCell>
                                                    <TableCell style={{border: 'none', fontSize: '0.8vw'}} align="right">Last Modified</TableCell>
                                                    <TableCell style={{border: 'none', fontSize: '0.8vw'}} align="right">Modified By</TableCell>
                                                    <TableCell style={{border: 'none', fontSize: '0.8vw'}}></TableCell>
                                                    
                                                </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {version.workpapers.map((workpaper, index) => (
                                                        <TableRow key={index} style={{borderTop: '1px solid rgb(255, 255, 255, 0.1)', fontSize: '0.8vw'}}>
                                                        
                                                            <TableCell style={{border: 'none', fontSize: '0.8vw', lineHeight: '1.5vw'}} component="th" scope="row">
                                                            {version.version}.{workpaper.subVersion}
                                                            </TableCell>
                                                            <TableCell style={{border: 'none', fontSize: '0.8vw', lineHeight: '1.5vw'}} align="right">{workpaper.lastModified}</TableCell>
                                                            <TableCell style={{border: 'none', fontSize: '0.8vw', lineHeight: '1.5vw'}} align="right">{workpaper.modifiedBy}</TableCell>
                                                            <TableCell style={{border: 'none', fontSize: '0.8vw', lineHeight: '1.5vw'}} align="right">
                                                            <ThemeProvider theme={darkTheme} style={{fontSize: '0.8vw', lineHeight: '1vw'}}>
                                                                <IconButton style={{height: '0.8vw', fontSize: '0.8vw', width:  '0.8vw'}} aria-label="delete" onClick={(e) => handleClick(e, requestId, workpaper.subVersion)} id={requestId + workpaper.subVersion + 'button'}>
                                                                    <MoreVertIcon style={{height: '1.5vw', width:  '1.5vw'}} />
                                                                </IconButton>
                                                            </ThemeProvider>
                                                            </TableCell>
                                                    
                                                        </TableRow>
                                                    ))}
                                                    
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </AccordionDetails> }
                                    { version.status != 'done' && <p style={{fontSize: '0.8vw', margin: '1vw'}}>
                                        {version.status_details}
                                    </p> }
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        MenuListProps={{
                                        'aria-labelledby': currentRequestId + currentSubVersion + 'button',
                                        }}
                                    >
                                        {/* { currentSubVersion != 0 && <MenuItem onClick={() => {editHandler(currentRequestId, currentSubVersion); handleClose()}}>Edit</MenuItem>} */}
                                        {/* { currentSubVersion == 0 && <MenuItem onClick={() => {editHandler(currentRequestId, currentSubVersion); handleClose()}}>View</MenuItem>} */}
                                        {/* <MenuItem onClick={() => {replicateHandler(currentRequestId, currentSubVersion); handleClose()}}>Replicate</MenuItem> */}
                                        <MenuItem onClick={async () => {await downloadActivityStatement(selectedGroup.clientName, selectedGroup.clientAbn, selectedGroup.startDate, selectedGroup.endDate); handleClose()}}>Download Activity Statement</MenuItem>
                                        <MenuItem onClick={() => {downloadHandler(currentRequestId, selectedGroup.clientName, `${new Date(selectedGroup.startDate).toLocaleString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })} to ${new Date(selectedGroup.endDate).toLocaleString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })}`); handleClose()}}>Download Workpaper</MenuItem>
                                    </Menu>
                                </Accordion>
                            )
                        })}
                        </div>
                </div>}

                { isLoading && <div style={{height: '40vh', width: '100%', textAlign: 'center', marginTop: '15vh'}}>
                    <CircularProgress color="inherit" />
                </div> }
                
            </Modal.Body>
            <SessionExpiredModal show={sessionExpired}/>
            </Modal>
    )   
}

