import Modal from 'react-bootstrap/Modal';
import { HotTable } from '@handsontable/react';
import React, { useState, useEffect, useRef, useContext } from 'react';
import { useAuth } from 'react-oidc-context';

import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';


import styles from '../Gayatri.module.css'
import { Box, Button, ButtonBase, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { setStep } from '../../../app/stepSlice';
import SessionExpiredModal from '../../../utils/SessionExpiredModal';
import { Form } from 'react-bootstrap';
import { SnackbarContext } from '../../../App';


import SearchIcon from '@mui/icons-material/Search';
import ConfirmationModal from '../../../utils/ConfirmationModal';
import MessageModal from '../../../utils/MessageModal';
import MessageModalModified from '../../../utils/MessageModalModified';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });


const currentDate = new Date();
const currentYear = currentDate.getFullYear();


export default ({show, onHide, asAccounts, onRefresh}) => {
    

    const [selectedAs, setSelectedAs] = useState('xero')

    const [isXeroSyncing, setIsXeroSyncing] = useState(false)

    const [isMyobSyncing, setIsMyobSyncing] = useState(false)

    const [isSyncing, setIsSyncing] = useState(false)

    const navigate = useNavigate()
    const auth = useAuth()

    const { snackBarShow, setSnackBarShow, snackBarMessage, setSnackBarMessage } = useContext(SnackbarContext);

    const [sessionExpired, setSessionExpired] = useState(false)

    const [currentStep, setCurrentStep] = useState(1)

    const [xeroClients, setXeroClients] = useState([])

    const [myobClients, setMyobClients] = useState([])

    const [clients, setClients] = useState([])

    const [subscriptionRequiredShow, setSubscriptionRequiredShow] = useState(false)


    const [confirmRescanShow, setConfirmRescanShow] = useState(false)

    const [maxClient, setMaxClients] = useState(false)

    

    function onClose (){
        onHide()
    }

    function nextStepHandler (){
        setCurrentStep((current_step) => current_step+1)
        
    }

    function fetchXeroCurrentClients(){
        fetch(`${window.origin}/api/creator/client-sync?as=xero`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${auth.user?.access_token}`
            }
        }).then(response => {
            return response.json();
        }).then(data => {
            setXeroClients(data)
            
        }).catch((error) => {
            console.error(error);
        });
    }


    function fetchMyobCurrentClients(){
        fetch(`${window.origin}/api/creator/client-sync?as=myob`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${auth.user?.access_token}`
            }
        }).then(response => {
            return response.json();
        }).then(data => {

            setMyobClients(data)
            
            
        }).catch((error) => {
            console.error(error);
        });
    }

    function fetchXeroSyncStatus(){
        fetch(`${window.origin}/api/creator/sync-status?as=xero`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${auth.user?.access_token}`
            }
        }).then(response => {
            return response.json();
        }).then(data => {
            setIsXeroSyncing(data.status)
        }).catch((error) => {
            console.error(error);
        });
    }

    function fetchMyobSyncStatus(){
        fetch(`${window.origin}/api/creator/sync-status?as=myob`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${auth.user?.access_token}`
            }
        }).then(response => {
            return response.json();
        }).then(data => {
            setIsMyobSyncing(data.status)
        }).catch((error) => {
            console.error(error);
        });
    }

    function asSelectHandler() {
        nextStepHandler()
    }
    
    function rescanClientsHandler(){
        fetch(`${window.origin}/api/creator/rescan-clients?as=${selectedAs}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${auth.user?.access_token}`
            }
        }).then(response => {
            if(response.status == 200){
                setSnackBarMessage('Re-fetching your clients')
                setSnackBarShow(true)
                if(selectedAs == 'myob'){
                    fetchMyobSyncStatus()
                }else if (selectedAs == 'xero'){
                    fetchXeroSyncStatus()
                }
            }else{
                setSnackBarMessage('Unknown error. Please try again later')
                setSnackBarShow(true)
            }
            setConfirmRescanShow(false)
        }).catch((error) => {
            console.error(error);
        });
    }



    function updateSyncStatusHandler(){

        if(!isSyncing){
            if(clients.length > maxClient){
                setSubscriptionRequiredShow(true)
                return
            }
            if(clients.length == 0){
                setSnackBarMessage('No client to sync. Please re-scan your clients')
                setSnackBarShow(true)
                return
            }
        }


        fetch(`${window.origin}/api/creator/sync-status?as=${selectedAs}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${auth.user?.access_token}`
            },
            body: JSON.stringify({status: !isSyncing})
        }).then(response => {
            if(response.status == 200){
                if(isSyncing){
                    setSnackBarMessage('Client Syncing paused')
                }else{
                    setSnackBarMessage('Client Syncing started')
                }
                
                if(selectedAs == 'myob'){
                    setIsMyobSyncing(!isSyncing)
                }else if (selectedAs == 'xero'){
                    setIsXeroSyncing(!isSyncing)
                }
                setSnackBarShow(true)
               
            }
        }).catch((error) => {
            console.error(error);
        });
    }

    useEffect(() => {
        if(selectedAs == 'myob'){
            setIsSyncing(isMyobSyncing)
            setClients(myobClients)
        }else if (selectedAs == 'xero'){
            setIsSyncing(isXeroSyncing)
            setClients(xeroClients)
        }
    }, [selectedAs, xeroClients, myobClients, isMyobSyncing, isXeroSyncing])


    useEffect(() => {
        let interval = setInterval(() => {
            fetchXeroCurrentClients()
            fetchMyobCurrentClients()
            fetchXeroSyncStatus()
            fetchMyobSyncStatus()
        }, 5000)

        fetch(`${window.origin}/api/subscription/maxclient`, {
            method: 'GET',
            headers: {
            Authorization: `Bearer ${auth.user?.access_token}`
            },
        })
        .then((response) => {
            if(response.status === 403){
                setSessionExpired(true);
                throw new Error('Session expired');
            }else{
                return response.json();
            }
        })
        .then((data) => {
            setMaxClients(data.limit)
        })
        .catch((error) => {
          console.error('Error fetching clients:', error);
        });

        return () => {
            clearInterval(interval);
            console.log("Interval cleared.");
          };
    }, [])




    return (
        
        <Modal
            show={show}
            onHide={onClose}
            dialogClassName="modal-50w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <SessionExpiredModal show={sessionExpired}/>
            <MessageModalModified show={subscriptionRequiredShow} onClose={() => {setSubscriptionRequiredShow(false)}} onClick={() => {navigate('/mydigitalworkers/gayatri/settings', {state: {tab: "SubscriptionSettings"}})}} message={'Upgrade Required'} description={'The number of clients exceeds your current plan. Please upgrade to proceed.'} buttonText={'Manage Subscription'}/>

            <ConfirmationModal onConfirm={rescanClientsHandler} show={confirmRescanShow} onClose={() => {setConfirmRescanShow(false)}} title={'Re-scan your clients'} message={'Re-scanning your clients will restart the syncing process. Are you sure you want to re-scan?'}/>
            
            <Modal.Body style={{padding: '2vw 2vw', textAlign: 'center'}}>
                <h4 style={{textAlign: 'center', fontSize: '1.3vw', marginBottom: '2vw', fontWeight: '500'}}>Sync Clients</h4>

                {currentStep == 1 && <>
                    <p style={{margin: 0, marginBottom: '2.5vw', fontWeight: '300', fontSize: '0.8vw', padding: '0 1vw'}}>Please select the accounting software to sync client from</p>
                    
                    <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center'}}>
                        
                        
                        {['xero', 'myob'].map(item => <>{ asAccounts.includes(item) && <ButtonBase onClick={() => {setSelectedAs(item)}} style={{display: 'flex', flexDirection: 'column', backgroundColor: selectedAs == item? 'rgb(255, 255, 255, 0.9)':'rgb(255, 255, 255, 0.05)', padding: '1vw', marginRight: '0.5vw', marginLeft: '0.5vw', borderRadius: '1vw', color:  selectedAs == item? 'rgb(0, 0, 0, 0.8)': 'rgb(255, 255, 255, 0.8)', width: '50%'}}>
                            <p style={{fontWeight: '600', marginBottom: '1vw', fontSize: '1.1vw'}}>{item.toUpperCase()}</p>
                            <p style={{fontWeight: '400', marginBottom: '1vw', fontSize: '0.7vw'}}>This option allows you to view existing clients on {item.toUpperCase()} and sync them to Gayatri</p>
                        </ButtonBase>}</>)}


                    </div>
                    
                    

                    <Button onClick={asSelectHandler}  variant="outlined" style={{
        borderColor: 'rgb(255, 255, 255, 1)', color: 'rgb(255, 255, 255, 0.9)', padding: '0.7vw', marginBottom: '1vw', border: 'none', backgroundImage: 'linear-gradient(rgb(255, 255, 255, 0.05), rgb(10, 10, 10, 0.05)),linear-gradient(#f25252, #f25252)', borderRadius: '0.5vw', textTransform: 'none', fontSize: '1vw', fontWeight: 500, letterSpacing: '0.08vw'
    , width: '20vw', marginTop: '2vw'}}>Next Step</Button>

                </>}

                {currentStep == 2 && <>
                    <p style={{margin: 0, marginBottom: '2.5vw', fontWeight: '300', fontSize: '0.8vw', padding: '0 1vw'}}>Review client list and sync your clients</p>
                
                    

                    <div style={{backgroundColor: 'rgb(0, 0, 0, 0.1)', borderRadius: '0.5vw', margin: '0 2vw'}}>
                        <ListItemButton style={{backgroundColor: 'rgb(255, 255, 255, 0.05)', paddingLeft: '1vw', borderTopLeftRadius: '0.5vw', borderTopRightRadius: '0.5vw'}}>
                            <div style={{display: 'flex', flexDirection: 'row', width: '100%', textAlign: 'left'}}>
                                <div style={{flex: 4, fontSize: '0.8vw'}}>{`Client`}</div>
                                <div style={{flex: 1, fontSize: '0.8vw'}}>{`Status`}</div>
                            </div>
                        </ListItemButton>

                        <Box style={{width: '100%'}}
                            sx={{ width: '100%', maxHeight: '20vw', width: '100%', bgcolor: 'rgb(0, 0, 0, 0)', overflowY: 'scroll' }}
                            >
                                
                            {clients.map((client) => 
                                <ListItemButton style={{backgroundColor: 'rgb(0, 0, 0, 0.1)', width: '100%', paddingLeft: '1vw'}}>
                                    <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                                        <div style={{flex: 4, fontSize: '0.7vw'}}>{`${client.client_name}`}</div>
                                        <div style={{flex: 1, fontSize: '0.7vw', color: 'rgb(255, 255, 255, 0.8)'}}>{client.synced}</div>
                                    </div>
                                </ListItemButton>)}
                        </Box>
                    </div>

                    <ButtonBase onClick={() => {setConfirmRescanShow(true)}} style={{margin: '1.5vw 0', fontSize: '0.8vw', padding: '0.3vw', borderRadius: '0.2vw', fontWeight: '500'}}><SearchIcon style={{marginRight: '0.3vw'}}/> Re-scan your clients</ButtonBase>
                    

                    <Button onClick={updateSyncStatusHandler} variant="outlined" style={{
        borderColor: 'rgb(255, 255, 255, 1)', color: 'rgb(255, 255, 255, 0.9)', padding: '0.7vw', marginBottom: '1vw', border: 'none', backgroundImage: 'linear-gradient(rgb(255, 255, 255, 0.05), rgb(10, 10, 10, 0.05)),linear-gradient(#f25252, #f25252)', borderRadius: '0.5vw', textTransform: 'none', fontSize: '1vw', fontWeight: 500, letterSpacing: '0.08vw'
    , width: '20vw', marginTop: '0vw'}}>{isSyncing? 'Cancel Syncing' :'Sync Clients'}</Button>
                    <p style={{fontSize: '0.8vw', color: 'rgb(255, 255, 255, 0.7)'}}>Sync from another accouting software? <span onClick={() => {setCurrentStep((currentStep) => currentStep - 1)}} style={{color: '#f25252', fontWeight: '500', cursor: 'pointer'}}>Previous Step</span></p>

                </>}






            </Modal.Body>
        </Modal>
    )
}



