
import {
  Outlet, 
  Link,
  useLocation,
  NavLink
} from "react-router-dom";
import React, {
  Suspense, useEffect,
} from 'react';
import { 
  useNavigate, 
  // useSearchParams,
} from "react-router-dom";

import { useAuth } from "react-oidc-context";

import { useState } from 'react'

import { motion } from "framer-motion";

import { useDispatch, useSelector } from "react-redux";

import MenuBar from "../../utils/MenuBar";
import SetUpRequired from "./SetUpRequired";
import { CircularProgress } from "@mui/material";
import QuickAccessBar from "../../utils/QuickAccessBar";
import TransparentButton from "../../utils/TransparentButton";


const pageVariants = {
  initial: {
    opacity: 0
  },
  in: {
    opacity: 1
  },
  out: {
    opacity: 0
  }
};

const pageTransition = {
  type: "tween",
  ease: "linear",
  duration: 0.15
};


function Layout() {

  const auth = useAuth();

  const location = useLocation()

  const navigate = useNavigate()

  const [colorChange, setColorchange] = useState(false);

  const tier = useSelector((state) => state.step.tier)

  const [role, setRole] = useState('User')

  const [ isLoading, setIsLoading ] = useState(false)

  let navChangedColorHeight;

  if(location.pathname == '/'){
    navChangedColorHeight = window.innerHeight
  }else{
    navChangedColorHeight = window.innerHeight/2
  }

  const changeNavbarColor = (scrollTop) =>{
    if(scrollTop>0){
      setColorchange(true);
    }
    else{
      setColorchange(false);
    }
  };

  let scroller = document.querySelector("#scroller")
  scroller?.addEventListener("scroll", (event) => {
    changeNavbarColor(scroller.scrollTop)
  });

  function openReporterViewRequestsModal(){
    navigate('/mydigitalworkers/Scout/requests', {state: {selectedTier: tier}})
  }

  function openReporterViewReportsModalFromMain(){
    navigate('/mydigitalworkers/Scout/reports', {state: {
      request: {
      customerSelectionMode: 'S',
      customersSelected: [],
      periodMode: 'D',
      financialYear: '2023',
      periodFrom: null,
      periodTo: null,
      selectedCustomer: [],
      },
      selectedTier: tier
    }})
  }

  useEffect(() => {
    setIsLoading(true)
    fetch(`${window.origin}/api/reporter/users/${auth.user?.profile.sub}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${auth.user?.access_token}`
      }
    }).then(resp => resp.json()).then(data => {

      console.log(data)

      setRole(data.role)
      setIsLoading(false)
    })
  }, []);

  return (
    <div style={{ textAlign: 'left', position: 'relative', height: '100vh', display: 'flex', flexDirection: 'row' }}>
      {/* <MenuBar /> */}

      <SetUpRequired>

        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100vh' }}>
          <>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
              <div style={{ flex: 1, display: "flex", flexDirection: "column", height: '6vw' }}>
                <div style={{flex: 2, marginLeft: '2vw', marginRight: '2vw', marginTop: '2.5vh'}} >
                  <p style={{fontSize: '1.8vw', marginBottom: '0.4vw'}}>Scout</p>
                  <p style={{fontSize: '0.75vw', color: 'rgb(255, 255, 255, 0.7)'}}>Discover the future with precision automation</p>
                </div>
              </div>
              <div style={{flex: 1, fontSize: '1.8vh', textAlign: 'center', flexDirection: 'row', marginLeft: '1.8vw', marginRight: '2vw', paddingTop: '2.5vw'}}>
                    <div style={{display: 'inline-block', backgroundColor: 'rgb(255, 255, 255, 0.1)', borderRadius: '2vw', padding: '0.2vw'}}>
                      <span style={{}}><TransparentButton id="workpaper-tab" onClick={openReporterViewRequestsModal} selected={location.pathname.includes("requests")}>Requests</TransparentButton></span>
                      <span style={{}}><TransparentButton id="clients-tab" onClick={() => {navigate('/mydigitalworkers/Scout/clients')}} selected={location.pathname.includes("clients")}>Clients</TransparentButton></span>
                      { auth.user?.profile.groups.includes("client-admin") && <span style={{}}><TransparentButton id="settings-tab" onClick={() => {navigate('/mydigitalworkers/Scout/settings')}} selected={location.pathname.includes("settings")}>Settings</TransparentButton></span>}
                    </div>
              </div>
              <div style={{paddingTop: '1.5vw', paddingRight: '1.5vw', flex: 1, display: 'flex', alignItems: 'flex-start'}}>
                <div style={{flex: 1, textAlign: 'right'}}>
                  <QuickAccessBar/>
                </div>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", flex: 1, height: 'calc(100vh - 6vw)'}}>
              <div className='mainContainer' style={{ flex: 1, display: 'flex', overflow: 'auto'}}>
                <div style={{flex: 1, width: '100%'}}>
                  <div style={{height: "calc(100vh - 6vw)", overflowY: 'auto'}}>
                    <Suspense>
                      <motion.div
                          key={location.pathname}
                          initial="initial"
                          animate="in"
                          variants={pageVariants}
                          transition={pageTransition}
                        >
                        <Outlet />
                      </motion.div>
                    </Suspense>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      
      </SetUpRequired>
    </div>
    // <div style={{ textAlign: 'left', position: 'relative', height: '100%', display: 'flex', flexDirection: 'row' }}>

    //   <SetUpRequired>

    //   { !isLoading &&   
    //     <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100vh' }}>
    //       <>

    //         <div style={{display: 'flex', flexDirection: 'row'}}>
    //           <div style={{ flex: 1, display: "flex", flexDirection: "column", height: '19vh' }}>
    //             <div style={{flex: 2, marginLeft: '2vw', marginRight: '2vw', marginTop: '2.5vh'}} >
    //               <p style={{fontSize: '3.6vh', marginBottom: '0.4vw'}}>Scout</p>
    //               <p style={{fontSize: '1.5vh', color: 'rgb(255, 255, 255, 0.7)'}}>Discover the future with precision automation</p>
    //             </div>
    //             <div style={{flex: 1, fontSize: '1.8vh', display: 'flex', alignItems: 'center', flexDirection: 'row', marginLeft: '1.8vw', marginRight: '2vw'}}>
    //                 <span style={{marginRight: '0.8vw'}}><TransparentButton onClick={openReporterViewRequestsModal} selected={location.pathname.includes("requests")}>Requests</TransparentButton></span>
    //                 <span style={{marginRight: '0.8vw'}}><TransparentButton onClick={openReporterViewReportsModalFromMain} selected={location.pathname.includes("reports")}>Reports</TransparentButton></span>
    //                 <span style={{marginRight: '0.8vw'}}><TransparentButton onClick={() => {navigate('/mydigitalworkers/Scout/clients')}} selected={location.pathname.includes("clients")}>Clients</TransparentButton></span>
    //                 <span style={{marginRight: '0.8vw'}}><TransparentButton onClick={() => {navigate('/mydigitalworkers/Scout/settings')}} selected={location.pathname.includes("settings")}>Settings</TransparentButton></span>
    //             </div>
    //           </div>
    //           <div style={{paddingTop: '1.5vw', paddingRight: '1.5vw'}}>
    //             <QuickAccessBar/>
    //           </div>
    //         </div>

    //         <div style={{ display: "flex", flexDirection: "column", flex: 1, height: 'calc(100vh - 20vh)'}}>
    //           <div className='mainContainer' style={{ flex: 1, display: 'flex', overflow: 'scroll'}}>
    //             {/* left */}
    //             <div style={{flex: 1, width: '100%'}}>
    //               <div className="layoutContainer" style={{height: "calc(100vh - 20vh)"}}>
    //                 <Suspense>
    //                   <motion.div
    //                       key={location.pathname}
    //                       initial="initial"
    //                       animate="in"
    //                       variants={pageVariants}
    //                       transition={pageTransition}
    //                     >
    //                     <Outlet />
    //                   </motion.div>
    //                 </Suspense>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </>
    //     </div>
    //   }

    //   { isLoading && 
    //     <div style={{height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    //       <CircularProgress  color="inherit"/>
    //     </div>
    //   }

    //   </SetUpRequired>
    // </div>
  )
}

export default Layout;