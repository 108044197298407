import { ButtonBase } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import AccountDetailsModal from './AccountDetailsModal';

function TaxAgentNumberSettings() {
    const [isEditing, setIsEditing] = useState(false);
    const [taxAgentNumber, setTaxAgentNumber] = useState('123 456 789');

    const [asAccounts, setAsAccounts ] = useState([])

    const [detailModalShow, setDetailModalShow] = useState()

    const [accountType, setAccountType] = useState()

    const auth = useAuth()

    function fetchData(){
        fetch(`${window.origin}/api/creator/as-accounts`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${auth.user?.access_token}`
            }
        }).then(response => {
            return response.json();
        }).then(data => {
            setAsAccounts(data)
        }).catch((error) => {
            console.error(error);
        });
    }


    useEffect( () => {
        fetchData()
        let timer = setInterval(() => {
            fetchData()
        }, 5000)

        return () => {
            clearInterval(timer);
            console.log("Interval cleared.");
          };
    }, [])

    return (
        <div>
            <AccountDetailsModal show={detailModalShow} accountType={accountType} onHide={() => {setDetailModalShow(false)}} fetchData={fetchData}/>
            <p style={{ fontSize: '0.8vw', color: 'rgb(255, 255, 255, 0.6)', fontWeight: '300' }}>
            Configure integration to third-party Accounting Softwares.
            </p>
            
            <div style={{width: '100%'}}>
                { asAccounts.map( (item, index) => <ButtonBase 
                    onClick={() => {
                        setAccountType(item.acc_type)
                        setDetailModalShow(true)
                    }}
                    style={{ 
                        flex: 1,
                        display: 'flex', 
                        flexDirection: 'column', 
                        backgroundColor: 'rgb(255, 255, 255, 0.06)', 
                        padding: '1vw 1.5vw', 
                        borderRadius: '0.7vw', 
                        // border: '1px solid rgb(255, 255, 255, 0.05)', 
                        marginTop: '1.5vw', 
                        marginBottom: '1.5vw',
                        width: '100%',
                        alignItems: 'flex-start'
                    }}
                >   
                    <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'flex-start'}}>
                        <div style={{ flex: 1, fontWeight: '500', fontSize: '1vw', flex: 1 }}>
                            <p style={{ marginBottom: '0.4vw', fontSize: '0.9vw', color: 'rgb(255, 255, 255, 0.6)', textAlign: 'left', marginTop: '0.5vw'}}>{String(item.acc_type).toUpperCase()} Account</p>
                        </div>
                        <div style={{ flex: 1, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end' }}>
                            
                                <p style={{ textAlign: 'right', color: 'rgb(255, 255, 255, 0.8)', margin: 0, fontSize: '0.8vw', flex: 1 }}>
                                    {item.verified}
                                </p>
                            

                        </div>
                    </div>
                    <p style={{paddingBottom: '1vw', color: 'rgb(255, 255, 255, 0.9)', margin: 0, fontSize: '1.2vw', flex: 1, fontWeight: '400', letterSpacing: '0.05vw'}}>
                        {item.username.length>0? item.username: 'Not Provided'}
                    </p>
                    <p style={{fontSize: '0.7vw', fontWeight: '300', color: 'rgb(255, 255, 255, 0.7)', marginBottom: '0.5vw'}}>This account will be used to access your clients on {String(item.acc_type).toUpperCase()} to generate workpaper.</p>
                    
                </ButtonBase>)}


            </div>
        </div>
    );
}

export default TaxAgentNumberSettings;
