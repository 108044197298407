import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SpeciaLogoWithName from '../../resources/SpeciaLogoWithName.png'

import SpeciaLogo2 from '../../resources/circle specia 2.png'
import { useAuth } from 'react-oidc-context';
import { Backdrop, Button, CircularProgress, createTheme, styled } from '@mui/material';
import { Form } from 'react-bootstrap';
import MessageModal from '../../utils/MessageModal';
import SessionExpiredModal from '../../utils/SessionExpiredModal';

import validator from 'validator'
import { SnackbarContext } from '../../App';


const TextButton = styled(Button)(({ theme }) => ({
    color: 'white',
    textTransform: 'none',
    padding: '0.5vw 0.75vw'
    }));

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
    });

function CompanyDetails({ onNext }) {
    const { snackBarShow, setSnackBarShow, snackBarMessage, setSnackBarMessage } = useContext(SnackbarContext);
    const [companyName, setCompanyName] = useState('');
    const [ABN, setABN] = useState('');

    const [isLoading, setIsLoading] = useState(false)

    const [shortErrorMessage, setShortErrorMessage] = useState() 

    const [longErrorMessage, setLongErrorMessage] = useState() 

    const [modalShown, setModalShown] = useState(false)

    const [sessionExpired, setSessionExpired] = useState(false)

    const auth = useAuth()

    const navigate = useNavigate()

    function abnCallback(data){
        // console.log(data)
        return data
    }

    async function checkABN(abn){
        const response = await fetch(`https://abr.business.gov.au/json/AbnDetails.aspx?callback=abnCallback&abn=${abn}&guid=4323553b-7eca-4ef2-974d-97c38996e1a9`)
    
        const data = await response.text()

        let jsonData = eval(data)

        if(jsonData.Message.length !== 0){
            return false
        }
        
        return jsonData.EntityName
        

    }


    async function abnChangeHandler(e){
        console.log(e.target.value)
        setABN(e.target.value)
        if(isValidABN(e.target.value)){
            let compName = await checkABN(e.target.value)
            if(compName){
                setCompanyName(compName)
            }
        }
    }


    function isValidABN (ABN) {
        if(!ABN) {
            return false
        }

        if (ABN == ''){
            return false
        }

        const result = validator.isNumeric(ABN) && ABN.length === 11;
        if(result) {
            return ABN
        } else {
            return false
        }
    }


  async function createCompany(){

    setIsLoading(true)


    if(!isValidABN(ABN)){
        setSnackBarMessage('The provided ABN is not valid')
        setSnackBarShow(true)
        return
    }else{
        const abnStatus = await checkABN(ABN)

        if(!abnStatus){
            setSnackBarMessage('The provided ABN is not associated with any organization')
            setSnackBarShow(true)
            return
        }

      try {
          const response = await fetch(`${window.origin}/api/company/create`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  "Authorization": `Bearer ${auth.user?.access_token}`
              },
              body: JSON.stringify({
                compName: companyName,
                abn: ABN
               })
          });

            if(response.status === 403){
                setSessionExpired(true)
                throw new Error('Session expired');
            }

          if (!response.ok) {
              throw new Error('Network response was not ok ' + response.statusText);
          }

          const result = await response.json();

          // Handle the response from your API
          console.log('Company created successfully', result);
            setSnackBarMessage('Company Created')
            setSnackBarShow(true)
          // Fetch a new token
          auth.signinSilent()
          setIsLoading(false)
          navigate('/')
      } catch (error) {
            setSnackBarMessage('Unknown error occurred. Please try again later')
            setSnackBarShow(true)
      }
    }
  }

    return (
    <div style={{display:'flex', flexDirection:'column', width:'100vw', height:'100vh'}}>

        <div style={{display:'flex', flexDirection:'row', padding: '5vh 15vw'}}>
            <div style={{flex: 1}}>
                <img src={SpeciaLogoWithName} width="40%" style={{paddingBottom: '12vh'}}/>
                <img src={SpeciaLogo2} width="90%" style={{paddingBottom: '15vh'}}/> 
            </div>
            


            <div style={{flex: 1, display:'flex', flexDirection:'column', justifyContent:'center'}}>
                <div style={{paddingLeft: '10vw'}}>
                    <header>
                        <p style={{margin: '0px', color: 'white', fontSize: '1vw', fontWeight: 300}}>
                            <span style={{color: 'rgba(255, 255, 255, 0.7)', fontSize: '1vw'}}>Welcome to</span> Specia Platform,
                        </p>
                        <h1 style={{fontSize: '1.5vw', marginBottom: '1vw'}}>Tell us about your Organization</h1>
                        <p style={{color: 'rgba(255, 255, 255, 0.75)', fontSize: '0.85vw', margin: 0}}>Please fill in the details below to register your organization with us.</p>
                    </header>

                    <Form.Control
                      type="text"
                      value={ABN}
                      placeholder='Australian Business Number'
                      onChange={abnChangeHandler}
                      style={{
                          width: '100%',
                          borderRadius: '0.5vw',
                          backgroundColor: 'rgb(255, 255, 255, 0.05)',
                          fontSize: '0.85vw',
                          color: 'white',
                          border: 'none',
                          padding: '0.25vw 1vw',
                          margin: '0 auto',
                          margin: '1vw auto',
                          height: '3vw'
                      }}
                  />
                  <Form.Control
                      type="text"
                      value={companyName}
                      placeholder='Company Name'
                      onChange={(e) => setCompanyName(e.target.value)}
                      style={{
                          width: '100%',
                          borderRadius: '0.5vw',
                          backgroundColor: 'rgb(255, 255, 255, 0.05)',
                          fontSize: '0.85vw',
                          color: 'white',
                          border: 'none',
                          padding: '0.25vw 1vw',
                          marginBottom: '1.3vw',
                          height: '3vw'
                      }}
                  />
                  
              {/* <TextButton
                  onClick={createCompany}
                  style={{
                      borderRadius: '0.3vw',
                      background: '#FE475B',
                      border: 'none',
                      fontSize: '1vw',
                      color: 'white',
                      padding: '0.7vw 20px',
                      width:'100%',
                      cursor: 'pointer',
                      fontWeight: '600'
                  }}
              >
                  Confirm
              </TextButton> */}
              <Button onClick={createCompany} variant="outlined" style={{
                        borderColor: 'rgb(255, 255, 255, 1)', color: 'rgb(255, 255, 255, 0.9)', padding: '0.7vw', marginBottom: '1vw', border: 'none', backgroundImage: 'linear-gradient(rgb(255, 255, 255, 0.05), rgb(10, 10, 10, 0.05)),linear-gradient(#f25252, #f25252)', borderRadius: '0.5vw', textTransform: 'none', fontSize: '1vw', fontWeight: 500, letterSpacing: '0.08vw'
                    , width: '100%', marginTop: '0vw'}}>Continue</Button>
              <p onClick={() => navigate('/setup')} style={{fontSize: '0.8vw', color: 'rgb(255, 255, 255, 0.7)', marginTop: '1vw', cursor: 'pointer'}}><span dangerouslySetInnerHTML={{ __html: '&larr;' }} style={{}} /> Pervious</p>
              </div>
            </div>

        </div>
        <MessageModal show={modalShown} onClose={() => {setModalShown(false)}}/>
        <SessionExpiredModal show={sessionExpired}/>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
            onClick={() => {setIsLoading(false)}}
            >
            <CircularProgress color="inherit" />
        </Backdrop>
    </div>
    );
}

function Verification({ onNext, onBack }) {
    const auth = useAuth()

    const navigate = useNavigate()

    return (
        <div style={{display:'flex', flexDirection:'column', width:'100vw', height:'100vh'}}>

        <div style={{display:'flex', flexDirection:'row', padding: '5vh 15vw'}}>
            <div style={{flex: 1}}>
                <img src={SpeciaLogoWithName} width="40%" style={{paddingBottom: '12vh'}}/>
                <img src={SpeciaLogo2} width="90%" style={{paddingBottom: '15vh'}}/> 
            </div>
            


            <div style={{flex: 1, display:'flex', flexDirection:'column', justifyContent:'center'}}>
                <div style={{paddingLeft: '10vw'}}>
                    <header>
                        <p style={{margin: '0px', color: 'white', fontSize: '1vw', fontWeight: 300}}>
                            <span style={{color: 'rgba(255, 255, 255, 0.7)', fontSize: '1vw'}}>Welcome to</span> Specia Platform,
                        </p>
                        <h1 style={{fontSize: '1.5vw', marginBottom: '1vw'}}>Organization Verification</h1>
                        <p style={{color: 'rgba(255, 255, 255, 0.75)', fontSize: '0.85vw', margin: 0, marginBottom: '1vw'}}>Please wait while our team is working on verifying your organization details.</p>
                    </header>
                  
                    
                    <Button onClick={() => {navigate(-1)}} variant="outlined" style={{
                        borderColor: 'rgb(255, 255, 255, 1)', color: 'rgb(255, 255, 255, 0.9)', padding: '0.7vw', marginBottom: '1vw', border: 'none', backgroundImage: 'linear-gradient(rgb(255, 255, 255, 0.05), rgb(10, 10, 10, 0.05)),linear-gradient(#f25252, #f25252)', borderRadius: '0.5vw', textTransform: 'none', fontSize: '1vw', fontWeight: 500, letterSpacing: '0.08vw'
                    , width: '100%', marginTop: '1vw'}}>Previous</Button>
                </div>
            </div>

        </div>
    </div>
    );
}



export default function CompanySetup() {
  const [step, setStep] = useState(0);
  
  const auth = useAuth()

  const handleNext = () => {
      setStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
      setStep((prevStep) => prevStep - 1);
  };

    useEffect(() => {
        if(auth.user?.profile.accountant && auth.user?.profile.accountant.ssid ){
            setStep(1)
        }
    })

  return (
      <div style={{width:'100vw', height:'100vh'}}>
          {step === 0 && <CompanyDetails onNext={handleNext} onBack={handleBack} />}
          {step === 1 && <Verification onNext={handleNext} onBack={handleBack} />}
      </div>
  );
}
