import { Button, CircularProgress, createTheme, styled } from '@mui/material';
import { React, useEffect, useState } from 'react'
import { ButtonGroup, Modal, Offcanvas, ToggleButton } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useAuth } from 'react-oidc-context';
import SessionExpiredModal from '../../utils/SessionExpiredModal';
import TransparentButton from '../../utils/TransparentButton';


const TextButton = styled(Button)(({ theme }) => ({
    color: 'white',
    textTransform: 'none',
    padding: '0.5vw 0.75vw'
  }));


const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

export default ({show, onClose, userId}) => {

    const auth = useAuth()

    const [firstName, setFirstName] = useState('');

    const [lastName, setLastName] = useState('');

    const [email, setEmail] = useState('');

    const [role, setRole] = useState('');

    const [isLoading, setIsLoading] = useState(false)

    const [sessionExpired, setSessionExpired] = useState(false)


    function saveRoleHandler(userId, newRole){
        fetch(`${window.origin}/api/users/${userId}/role`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${auth.user?.access_token}`
            },
            body: JSON.stringify({
                "desiredRole": newRole
            })
        }).then((response) => {
            if(response.status === 403){
                setSessionExpired(true)
                throw new Error('Session expired');
            }
            return response.json();
        }).catch((error) => {
            console.error('Failed to fetch workers:', error);
        });
        onClose()
    }

    function closeHandler(){
        onClose()
        setTimeout(()=> {
            setFirstName('')
            setLastName('')
            setEmail('')
            setRole('')
        }, 200)
    }
    
    useEffect(() => {
        if(userId !== ''){
            setIsLoading(true)
            fetch(`${window.origin}/api/users/${userId}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${auth.user?.access_token}`
                }
            }).then((response) => {
                if(response.status === 403){
                    setSessionExpired(true)
                    throw new Error('Session expired');
                }
                return response
            }).then(resp => resp.json()).then(data => {

                console.log(data)
                setFirstName(data.firstName)

                setLastName(data.lastName)

                setEmail(data.email)

                setRole(data.role)
                setIsLoading(false)
            }).catch((error) => {
                console.error(error);
            });
        }
        
    }, [userId]);
    
    return (
        <>
            <SessionExpiredModal show={sessionExpired}/>

            <Modal
                show={show}
                onHide={closeHandler}
                dialogClassName="modal-50w"
                aria-labelledby="example-custom-modal-styling-title"
            >
            
                <Modal.Body style={{padding: '2vw 3vw', paddingBottom: '0vw', textAlign: 'center'}}>

                    <h4 style={{textAlign: 'center', fontSize: '1.3vw', marginBottom: '2vw', fontWeight: '500'}}>{firstName} {lastName}</h4>

                    {/* <p style={{margin: 0, marginBottom: '2.5vw', fontWeight: '300', fontSize: '0.8vw', padding: '0 2vw', color: 'rgb(255, 255, 255, 0.7)'}}>Please provide details of the user you want to invite.</p> */}

                        <p style={{fontSize: '0.8vw', textAlign: 'left', marginBottom: '1vw', marginLeft: '0.5vw', color: 'rgb(255, 255, 255, 0.7)'}}>Profile</p>
                        <div style={{margin: '0.5vw 0vw 2vw 0vw', backgroundColor: 'rgb(0, 0, 0, 0.2)', borderRadius: '1vw'}}>
                            <div style={{display: 'flex', flexDirection: 'row', padding: '1vw 1.2vw', borderTopLeftRadius: '1vw', borderTopRightRadius: '1vw', marginTop: '0', marginBottom: '0'}}>
                                <div style={{flex: 1, fontWeight: '500', fontSize: '0.8vw'}}>
                                    <p style={{margin: 0, textAlign: 'left'}}>First Name</p>
                                </div>
                                <div style={{flex: 1}}>
                                    <p style={{textAlign: 'right', color: 'rgb(255, 255, 255, 0.7)', margin: 0, fontSize: '0.8vw'}}>{firstName}</p>
                                </div>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'row', padding: '1vw 1.2vw', borderRadius: '0', marginTop: '0', marginBottom: '0'}}>
                                <div style={{flex: 1, fontWeight: '500', fontSize: '0.8vw'}}>
                                    <p style={{margin: 0, textAlign: 'left'}}>Last Name</p>
                                </div>
                                <div style={{flex: 1}}>
                                    <p style={{textAlign: 'right', color: 'rgb(255, 255, 255, 0.7)', margin: 0, fontSize: '0.8vw'}}>{lastName}</p>
                                </div>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'row', padding: '1vw 1.2vw', borderBottomLeftRadius: '1vw', borderBottomRightRadius: '1vw', marginTop: '0', marginBottom: '0'}}>
                                <div style={{flex: 1, fontWeight: '500', fontSize: '0.8vw'}}>
                                    <p style={{margin: 0, textAlign: 'left'}}>Email</p>
                                </div>
                                <div style={{flex: 1}}>
                                    <p style={{textAlign: 'right', color: 'rgb(255, 255, 255, 0.7)', margin: 0, fontSize: '0.8vw'}}>{email}</p>
                                </div>
                            </div>
                        </div>
                        <Form.Group style={{marginBottom: '1.5vw', flex: 1, textAlign: 'left'}}>
                            <p style={{fontSize: '0.8vw', textAlign: 'left', marginBottom: '1vw', marginLeft: '0.5vw', color: 'rgb(255, 255, 255, 0.7)'}}>Role Type</p>
                            
                            <div style={{display: 'inline-block', backgroundColor: 'rgb(0, 0, 0, 0.2)', borderRadius: '0.6vw', padding: '0.3vw'}}>
                      


                                {['Admin', 'User'].map( (roleType, index) => <span style={{}}>
                                    <TransparentButton style={{padding: '0.3vw 0.75vw', borderRadius: '0.5vw'}} onClick={() => {
                                        setRole(roleType)
                                    }} selected={role == roleType}>{roleType}</TransparentButton>
                                </span>)}

                    
                                
    
                            </div>
                            {/* <div class="dropdown">
                                <a class="btn btn-secondary dropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ color: 'white', backgroundColor: 'rgba(255,255,255,.1)', border: '1px solid rgb(255, 255, 255, 0.2)', borderRadius: '8px', fontWeight: '500', fontSize: '1.1rem', width: '100%', textAlign:'left', padding: '8px 20px'}}>
                                    {role}
                                </a>

                                <ul class="dropdown-menu" style={{width: "100%"}}>
                                    {['Admin', 'User'].map( (roleType, index) => 
                                        <li key={index} onClick={() => setRole(roleType)} style={{cursor: 'pointer'}}><a class="dropdown-item">{roleType}</a></li>
                                    )}
                                </ul>
                            </div> */}
                            <Button onClick={() => saveRoleHandler(userId, role)} variant="outlined" style={{
                                    borderColor: 'rgb(255, 255, 255, 1)', color: 'rgb(255, 255, 255, 0.9)', padding: '0.7vw', marginBottom: '1.5vw', border: 'none', backgroundImage: 'linear-gradient(rgb(255, 255, 255, 0.05), rgb(10, 10, 10, 0.05)),linear-gradient(#f25252, #f25252)', borderRadius: '0.5vw', textTransform: 'none', fontSize: '1vw', fontWeight: 500, letterSpacing: '0.08vw'
                                , width: '100%', marginTop: '2.5vw'}}>Save</Button>
                        </Form.Group>

                </Modal.Body>
            </Modal>
            {/* <Offcanvas show={show} onHide={closeHandler} placement="end" style={{width: '23vw', backgroundColor: 'rgb(32, 30, 34, 0.7)', backdropFilter: 'blur(50px)', '-webkit-backdrop-filter': 'blur(50px)',border: '1px solid rgb(255, 255, 255, 0.2)', paddingLeft: '1vw', paddingRight: '1vw', paddingTop: '1vw', color: 'white', display: 'flex', flexDirection: 'column', margin: '1rem', borderRadius: '2vw'}}>
                <div style={{flex: 1}}>
                    <Offcanvas.Header  style={{padding: '1vw'}}>
                    <Offcanvas.Title style={{fontSize: '1.3vw'}}>{firstName} {lastName}</Offcanvas.Title>
                    </Offcanvas.Header>
                    { !isLoading && <Offcanvas.Body style={{paddingTop: '2vw', height: '100%'}}>
                        <Form.Group style={{marginBottom: '2vw', flex: 1, textAlign: 'left'}}>
                            <p style={{fontSize: '0.9vw', marginBottom: '1vw'}}>First Name</p>
                            <Form.Control style={{height: '2vw', backgroundColor: 'rgba(255,255,255,.05)', border: '1px solid rgb(255, 255, 255, 0.15)', borderRadius: '0.5vw', fontSize: '0.8vw', paddingLeft: '0.8vw'}} type="email" placeholder="First Name" value={firstName} disabled/>
                        </Form.Group>
                        <Form.Group style={{marginBottom: '2vw', flex: 1, textAlign: 'left'}}>
                            <p style={{fontSize: '0.9vw', marginBottom: '1vw'}}>Last Name</p>
                            <Form.Control style={{height: '2vw', backgroundColor: 'rgba(255,255,255,.05)', border: '1px solid rgb(255, 255, 255, 0.15)', borderRadius: '0.5vw', fontSize: '0.8vw', paddingLeft: '0.8vw'}} type="email" placeholder="Last Name" value={lastName} disabled/>
                        </Form.Group>
                        <Form.Group style={{marginBottom: '2vw', flex: 1, textAlign: 'left'}}>
                            <p style={{fontSize: '0.9vw', marginBottom: '1vw'}}>Email Address</p>
                            <Form.Control style={{height: '2vw', backgroundColor: 'rgba(255,255,255,.05)', border: '1px solid rgb(255, 255, 255, 0.15)', borderRadius: '.5vw', fontSize: '0.8vw', paddingLeft: '0.8vw'}} type="email" placeholder="Email Address" value={email} disabled/>
                        </Form.Group>
                        <Form.Group style={{marginBottom: '3vw', flex: 1, textAlign: 'left'}}>
                            <p style={{fontSize: '0.9vw', marginBottom: '1vw'}}>Role</p>
                            <p style={{fontSize: '0.75vw', color: 'rgb(255, 255, 255, 0.6)'}}>The role selected corresponds to the level of access this user has in Gayatri.</p>

                            <div class="dropdown">

                                <ButtonGroup className="btn-group-toggle" style={{}}>
                                    <ToggleButton
                                        key={1}
                                        id={`radio-1`}
                                        type="radio"
                                        variant='outline-light'
                                        name="radio"
                                        value='ED'
                                        onChange={() => setRole('Admin')}
                                        style={{
                                            borderTopLeftRadius: '0.2vw',
                                            borderBottomLeftRadius: '0.2vw',
                                            fontSize: '0.8vw',
                                            padding: '0.3vw 0.75vw',
                                            backgroundColor: role == 'Admin' ? 'white' : '',
                                            borderColor: role == 'Admin' ? 'white' : '',
                                            color: role == 'Admin' ? 'black' : '', // Text color for active button
                                        }}                                            
                                    >
                                        Admin
                                    </ToggleButton>
                                    <ToggleButton
                                        key={2}
                                        id={`radio-2`}
                                        type="radio"
                                        variant='outline-light'
                                        name="radio"
                                        value='FY'
                                        onChange={() => setRole('User')}
                                        style={{
                                            borderTopRightRadius: '0.2vw',
                                            borderBottomRightRadius: '0.2vw',
                                            fontSize: '0.8vw',
                                            padding: '0.3vw 0.75vw',
                                            backgroundColor: role == 'User' ? 'white' : '',
                                            borderColor: role == 'User' ? 'white' : '',
                                            color: role == 'User' ? 'black' : '', // Text color for active button
                                        }}                                            
                                    >
                                        User
                                    </ToggleButton>
                                </ButtonGroup>
                            </div>
                        </Form.Group>
                        
                    </Offcanvas.Body>}
                    { isLoading && <>
                        <div style={{textAlign: 'center', paddingTop: '35vh'}}>
                            <CircularProgress color="inherit" />
                        </div>
                    </>}
                </div>
                <div style={{textAlign: 'right', paddingBottom: '1.5vw', paddingRight: '1vw'}}>

                    <TextButton variant='outlined' style={{ fontSize: '0.9vw', color: 'white', fontWeight: '500', padding: '0.25vw 1.5vw', marginRight: '1.25vw', borderRadius: '1.5vw', textDecoration: 'none' , borderColor: 'rgb(255, 255, 255, 0.15)', backgroundColor: 'rgb(0, 0, 0, 0.1)'}}  onClick={closeHandler}>
                        Close
                    </TextButton>
                    <Button variant='contained' style={{ fontSize: '0.9vw', color: 'white', fontWeight: '500', cursor: 'pointer', padding: '0.25vw 1.5vw', borderRadius: '1.5vw', backgroundColor: '#f25252', borderColor: '#f25252', textTransform: 'none'}}  onClick={() => saveRoleHandler(userId, role)}>
                        Save
                    </Button>
                </div>

                
            </Offcanvas> */}

        </>
            
    )
}