import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import MenuBar from '../../utils/MenuBar';
import { useAuth } from 'react-oidc-context';
import { ButtonBase, CircularProgress } from '@mui/material';

import BGD from "../../resources/BGD.png";

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SessionExpiredModal from '../../utils/SessionExpiredModal';

export default (props) => {
    const navigate = useNavigate();
    const auth = useAuth();

    const [isLoading, setIsLoading] = useState(false);
    const [workers, setWorkers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    const [sessionExpired, setSessionExpired] = useState(false)

    useEffect(() => {
        setIsLoading(true);

        fetch(`${window.origin}/api/digital-workers`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.user?.access_token}`
            },
        })
        .then((response) => {
            if(response.status === 403){
                setSessionExpired(true)
                throw new Error('Session expired');
            }
            return response.json()
        })
        .then((data) => {
            const adjustedData = data.map(worker => ({
                ...worker,
                path: worker.url
            }));
            setWorkers(adjustedData);
            setIsLoading(false);
        })
        .catch((error) => {
            console.error('Failed to fetch workers:', error);
        });
    }, [auth]);

    const filteredWorkers = workers.filter(worker => 
        worker.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (worker.category && worker.category.toLowerCase().includes(searchQuery.toLowerCase()))
    );

    return (
        <div style={{ }}>
            <SessionExpiredModal show={sessionExpired}/>
            <div className={props.imgClass} style={{textAlign: 'center', position: 'relative', display:'flex', flexDirection:'column', background: '#120922' }}>
                
                

                {!isLoading && 
                    <div style={{display:'flex', flexDirection:'column', width:'100%', padding:'2vw 4vw 0 4vw', margin: '2vw 0'}}>
                        <div style={{display: 'flex', flexDirection: 'row', margin: '0.5vw', alignItems: 'center'}}>
                           
                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems:'flex-start'}}>
                                <h2 style={{fontSize: '1.5vw', fontWeight: '500', color: 'rgb(255, 255, 255)', textShadow: '0 0 20px rgb(0, 0, 0, 0.3)'}}>Meet Your Digital Workers</h2>
                                <p style={{padding: 0, margin: 0, marginTop: '0.1vw', fontSize: '0.8vw', fontWeight: '500', color: 'rgb(255, 255, 255, 0.7)', textShadow: '0 0 10px rgb(0, 0, 0, 0.25)'}}>Boost your productivity</p>
                            </div>
                        </div>
                        
                        
                        <div style={{ width: '100%',  margin: '0.5vw', marginTop: '1.5vw'}}>
                            

                            {/* <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                                

                                <Form.Group style={{ textAlign:'left'}}>
                                    <i style={{fontSize: '0.9vw'}} className="fa-solid fa-magnifying-glass"></i>
                                    <Form.Control 
                                        value={searchQuery} 
                                        onChange={(e) => setSearchQuery(e.target.value)} 
                                        style={{display: 'inline', height: '45px', backgroundColor: 'rgba(255,255,255,0)', border: '1px solid rgb(255, 255, 255, 0)', borderRadius: '6px', fontSize: '1rem', maxWidth: '40%', marginLeft:'0.5vw'}} 
                                        type="text" 
                                        placeholder="Search"
                                    />
                                </Form.Group>
                                
                            </div> */}

                            <div id="digital-worker-list" style={{display:'flex', flexDirection:'column', marginBottom: '1.5vh', marginRight: '2vw'}}>
                            

                                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', gap: '1.5vw'}}>
                                    {filteredWorkers.map((worker, index) => (
                                        <div name={worker.name} key={index} style={{ width: '100%', display: 'flex', flexDirection: 'column', padding: '0', borderRadius: '1.5vw', border: '0 solid rgb(255, 255, 255, 0.1)'}}>
                                            <ButtonBase
                                                id={`digital-worker-${worker.name}`}
                                                onClick={() => { navigate(worker.path) }}
                                                style={{
                                                    width: '17vw',
                                                    height: '8vw',
                                                    borderRadius: '0.5vw',
                                                    border: '0.05vw solid rgb(75, 75, 75)',
                                                    backgroundColor: worker.name == 'Scout'? `rgb(255, 255, 255, 0.15)`: 'rgb(255, 255, 255, 0.15)',
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center',
                                                    boxShadow: '0 10px 10px hsl(0deg 0% 0% / 0.2)',
                                                    cursor: 'pointer',
                                                    display: 'flex',
                                                    justifyContent: 'start',
                                                    alignItems: 'start',
                                                    
                                                }}
                                            >
                                                <div style={{
                                                    flex: 1,
                                                    width: '100%',
                                                    width: '17vw',
                                                    height: '8vw',
                                                    borderRadius: '0.5vw',
                                                    backgroundImage: `linear-gradient(200deg, rgb(255, 255, 255, 0.0), rgb(10, 10, 10, 0.5))`,
                                                    display: 'flex', 
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}>
                                                    <p style={{margin: 0, fontSize: '2.3vw', fontWeight: '400', color: 'rgb(255, 255, 255, 0.8)', lineHeight: '2.3vw', letterSpacing: '0.02vw', textShadow: '0 10px 10px hsl(0deg 0% 0% / 0.3)',}}>{worker.name == 'Scout'? 'ato service':'workpaper'}</p>
                                                    <p style={{margin: 0, fontSize: '1.4vw', fontWeight: '300', color: 'rgb(255, 255, 255, 0.7)'}}>{worker.name}</p>

                                                </div>
                                            </ButtonBase>
                                            <div style={{padding: '0.2vw 0'}}>
                                                <p style={{ margin: '0 0.2vw 0.5vw 0.2vw', fontSize: '1.4vw', color: 'rgb(255, 255, 255, 0.9)', textAlign: 'left', fontWeight: '500', paddingTop: '0.5vw'}}>{worker.name}</p>
                                                <p style={{fontSize: '0.7vw', fontWeight: '400', margin: '0vw 0.2vw', textAlign: 'left', paddingBottom: '0', paddingTop: '0vw', color: 'rgb(255, 255, 255, 0.8)'}}>{worker.name == 'Scout'? 'ATO Data Retrieval Service':'Workpaper Generation Service'}</p>
                                                <p style={{fontSize: '0.75vw', fontWeight: '300', margin: '0vw 0.2vw', textAlign: 'left', paddingTop: '0.4vw', paddingBottom: '1.6vw', color: 'rgb(255, 255, 255, 0.5)'}}>{worker.name == 'Scout'? 'Efficiently fetches ATO data for multiple clients simultaneously.'.substring(0, 70): 'On-demand generation of year-end workpapers.'.substring(0, 70)}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {isLoading && 
                    <div style={{height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <CircularProgress  color="inherit"/>
                    </div>
                }
            </div>
        </div>
    );
}
