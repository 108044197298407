import Modal from 'react-bootstrap/Modal';
import { HotTable } from '@handsontable/react';
import React, { useState, useEffect, useRef, useContext } from 'react';
import { useAuth } from 'react-oidc-context';

import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import { Box, Button, ButtonBase, ListItem, ListItemButton, ListItemText } from '@mui/material';


import SearchIcon from '@mui/icons-material/Search';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });


const currentDate = new Date();
const currentYear = currentDate.getFullYear();


export default ({show, onClose, onConfirm, title, message}) => {
    

    



    return (
        
        <Modal
            show={show}
            onHide={onClose}
            dialogClassName="modal-30w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            
            <Modal.Body style={{padding: '2vw 2vw', textAlign: 'center'}}>
                <h4 style={{textAlign: 'center', fontSize: '1.3vw', marginBottom: '2vw', fontWeight: '500'}}>{title}</h4>

                <p style={{margin: 0, marginBottom: '2.5vw', fontWeight: '300', fontSize: '0.8vw', padding: '0 1vw'}}>{message}</p>
                    
            
                <Button onClick={onConfirm}  variant="outlined" style={{
        borderColor: 'rgb(255, 255, 255, 1)', color: 'rgb(255, 255, 255, 0.9)', padding: '0.7vw', marginBottom: '1vw', border: 'none', backgroundImage: 'linear-gradient(rgb(255, 255, 255, 0.05), rgb(10, 10, 10, 0.05)),linear-gradient(#f25252, #f25252)', borderRadius: '0.5vw', textTransform: 'none', fontSize: '1vw', fontWeight: 500, letterSpacing: '0.08vw'
    , width: '20vw', marginTop: '1vw'}}>Confirm</Button>

                <Button onClick={onClose}  variant="outlined" style={{
                        borderColor: 'rgb(255, 255, 255, 1)', color: 'rgb(255, 255, 255, 0.9)', padding: '0.7vw', marginBottom: '1vw', border: 'none', backgroundColor: 'rgb(255, 255, 255, 0.05)', borderRadius: '0.5vw', textTransform: 'none', fontSize: '1vw', fontWeight: 500, letterSpacing: '0.08vw'
                    , width: '20vw', marginTop: '1vw'}}>Cancel</Button>

                






            </Modal.Body>
        </Modal>
    )
}



