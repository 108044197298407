import React from "react";
import { 
    useEffect, 
    useState,
} from "react";
import { 
    Outlet,
    useNavigate, 
    // useSearchParams,
} from "react-router-dom";

import { useAuth } from "react-oidc-context";
import { CircularProgress, Fade, IconButton, ThemeProvider } from "@mui/material";
import SessionExpiredModal from "../../utils/SessionExpiredModal";
import SubscriptionRequired from "./Subscription/SubscriptionRequired";
import GayatriSetup from "./SetUp/GayatriSetup";
import QuickAccessBar from "../../utils/QuickAccessBar";

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';


import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { darkTheme } from "../../utils/funtions";


export default function AccessRequired(props) {



    const auth = useAuth()

    const navigate = useNavigate()

    const [currentStep, setCurrentStep ] = useState()

    const [setUpCurrentStep, setSetUpCurrentStep] = useState("tan")

    const [isLoading, setIsLoading] = useState(true)

    const [sessionExpired, setSessionExpired] = useState(false)



    


    return (
        <React.Fragment>
             {auth.user?.profile.groups.includes("gayatri_access") && props.children }

             {!auth.user?.profile.groups.includes("gayatri_access") && <>
                <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                  <div style={{paddingTop: '1.5vw', paddingRight: '1.5vw', paddingLeft: '1.5vw', display: 'flex', alignItems: 'flex-start'}}>
                      <div style={{flex: 1, textAlign: 'left'}}>
                       <ThemeProvider theme={darkTheme}>
                        <IconButton onClick={() => {navigate('/')}} aria-label="delete" color="primary" style={{padding: '0.75vw', display: 'flex', backgroundColor: 'rgb(255, 255, 255, 0.15)'}}>
                          <ArrowBackIcon style={{flex: 1, color: 'rgb(255, 255, 255, 0.8)'}} />
                        </IconButton>
                      </ThemeProvider> 
                      </div>
                      <div style={{flex: 1, textAlign: 'right'}}>
                        <QuickAccessBar/>
                      </div>
                      

                  </div>
                  <div style={{flex: 1, justifyContent: 'center', alignItems: 'center', paddingTop: '16vh'}}>
                    <div style={{textAlign: 'center'}}>
                      <RemoveCircleOutlineIcon style={{marginBottom: '2vw', fontSize: '10vw', color: 'rgb(255, 255, 255, 0.07)', fontWeight: '100'}}/>
                    </div>
                    <p style={{textAlign: 'center', fontSize: '2vw',marginBottom: '1vw', fontWeight: 300, color: 'rgb(255, 255, 255, 0.9)' }}>Access Required</p>
                    <p style={{textAlign: 'center', fontSize: '1vw',marginBottom: '1vw', fontWeight: 300, color: 'rgb(255, 255, 255, 0.8)'}}>You don't have permissions to access Gayatri</p>
                    <p style={{textAlign: 'center', fontSize: '1vw',marginBottom: '1vw', fontWeight: 100, color: 'rgb(255, 255, 255, 0.6)'}}>Please contact your Administrator</p>
                  </div>
                </div>
             </> }
              
            
        </React.Fragment>
    );
}