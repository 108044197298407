import { useEffect, useState } from "react"
import { Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap"
import { useAuth } from "react-oidc-context"
import UserTable from "./UserTable"
import DetailModal from "./DetailModal"
import UploadBulk from "./UploadBulk"
import InviteModal from "./InviteModal"
import SessionExpiredModal from "../../utils/SessionExpiredModal"
import { Button, IconButton, ThemeProvider, createTheme, styled } from "@mui/material"

import GroupAddIcon from '@mui/icons-material/GroupAdd';

import SearchIcon from '@mui/icons-material/Search';


const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  const TextButton = styled(Button)(({ theme }) => ({
    color: 'white',
    textTransform: 'none',
    padding: '0.5vw 0.75vw'
  }));

export default (props) => {

    const [userData, setUserData] = useState([])

    const [detailModalShown, setDetailModalShown] = useState(false)

    const [inviteModalShown, setInviteModalShown] = useState(false)

    const [uploadModalShow, setUploadModalShow] = useState(false)

    const [currentUserId, setCurrentUserId] = useState('')

    const [isLoading, setIsLoading] = useState(true)

    const [searchQuery, setSearchQuery] = useState('');

    const [sessionExpired, setSessionExpired] = useState(false)

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredUserData = userData.filter(user =>
        user.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.lastName.toLowerCase().includes(searchQuery.toLowerCase())
    );


    const auth = useAuth()


    function openModalHandler(userId){
        setDetailModalShown(true)
        setCurrentUserId(userId)
    }

    function closeDetailModalHandler(){
        setDetailModalShown(false)
        setCurrentUserId('')
    }

    function closeInviteModalHandler(){
        setInviteModalShown(false)
    }

    function closeUploadModal(){
        setUploadModalShow(false)
    }


    useEffect(()=> {
        if (auth.user?.profile.accountant.ssid){
            console.log('execute')
            fetch(`${window.origin}/api/users`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${auth.user?.access_token}`
                }
            }).then((response) => {
                if(response.status === 403){
                    setSessionExpired(true)
                    throw new Error('Session expired');
                }
                return response.json();
            }).then(data => {
                setUserData(data.filter(item => item.email !== auth.user?.profile.email))
                setIsLoading(false)
            }).catch((error) => {
                console.error('Failed to fetch workers:', error);
            });
        }
    }, [auth.user?.profile.accountant.ssid])


    return (
        <>  
            <SessionExpiredModal show={sessionExpired}/>
            <>
                <div style={{ margin: '0.5vw 0', marginBottom: '1vw', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div style={{backgroundColor: 'rgb(255, 255, 255, 0.08)', borderRadius: '50px', padding: '0.2vw'}}>
                        <ThemeProvider theme={darkTheme}>

                            <IconButton aria-label="delete" style={{backgroundColor: 'rgb(255, 255, 255, 0.15)', padding: '0.5vw', fontSize: '0.5vw', color: 'rgb(255, 255, 255, 0.7)', cursor: 'none'}}>
                                <SearchIcon style={{fontSize: '1vw'}}/>
                            </IconButton>
                        </ThemeProvider>
                        <Form.Control 
                            style={{
                                display: 'inline',  backgroundColor: 'rgba(255,255,255,0)', padding: 0, paddingLeft: '0.8vw', paddingRight: '0.8vw',
                                border: '1px solid rgb(255, 255, 255, 0)', borderRadius: '6px', fontSize: '0.9vw', maxWidth: '80%', marginLeft:'0' , fontWeight: '200'
                            }} 
                            className='shadow-none'
                            type="text" 
                            placeholder="Search" 
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                    </div>
                    <div style={{ flex: 1, textAlign: 'right', paddingLeft: '0.5vw'}}>
                    
                    <OverlayTrigger
                        key={'top-submit'}
                        placement={'top'}
                        overlay={
                            <Tooltip id={`tooltip-submit`}>
                                Invite New User
                            </Tooltip>
                        }
                        >
                            <TextButton variant='text' style={{ fontSize: '0.9vw',cursor: 'pointer', marginLeft: '0.5vw', padding: '0.75vw', minWidth: 0, borderRadius: '2vw', backgroundColor: 'rgb(255, 255, 255, 0.1)', marginLeft: '1vw'}} onClick={() => setInviteModalShown(true)}> 
                                <i className="fa-solid fa-plus" style={{fontSize: '0.9vw', width: '0.95vw', height: '0.95vw'}}></i>
                            </TextButton>
                        
                    </OverlayTrigger>
                        {/* <span style={{ fontSize: '0.9vw', paddingLeft: '1.8vw' }} onClick={() => setInviteModalShown(true)}><i className="fa-solid fa-plus" style={{ paddingRight: '8px', fontSize: '0.9vw' }}></i>Add</span>
                        <span style={{ fontSize: '0.9vw', paddingLeft: '1.8vw' }} onClick={() => setUploadModalShow(true)}><i className="fa-solid fa-arrow-up" style={{ paddingRight: '8px', fontSize: '0.9vw' }}></i>Upload</span> */}
                    </div>
                </div>


                <Modal.Body style={{margin: '0 0', padding: 0, fontSize: '1.6rem', lineHeight: '2.8rem', borderRadius: '20px'}}>
                    <div style={{width: '100%', textAlign: 'left'}}>
                        <UserTable userData={filteredUserData} openModalHandler={openModalHandler} isLoading={isLoading}/>
                    </div>
                </Modal.Body>
            </>
            <DetailModal show={detailModalShown} onClose={closeDetailModalHandler} userId={currentUserId}/>
            <InviteModal show={inviteModalShown} onClose={closeInviteModalHandler}/>
            <UploadBulk uploadModalShow={uploadModalShow} closeUploadModal={closeUploadModal}/>
        </>
    )
}